import React from 'react'
import './index.scss'
import { ApiGetProject, ApiPostCreateWorkHours, ApiPutUpdateWorkHours, ApiDeleteOneWorkHours, ApiGetWorkHoursList } from '@/api'
import { $i18n } from '@/lang'
import { message, Modal, Spin } from 'antd'
import BaseDialog from '@/components/base-dialog'
import SelectorPicker from '@/components/selector-picker'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import BaseButton from '@/components/base-button'
import { HOUR_LIST } from '@/utils/constants'

export interface Hours {
  id?: number,
  project: string,
  remark: string,
  status: boolean,
  work_date: number,
  working_hour: number
}

export interface FormData {
  title: string;
  currentTimestamp: number;
  projectId?: number;
  hoursDetail: Hours[];
}

interface HoursFormProps {
  visibled: boolean;
  formData: FormData;
  onFetchData?: (closeForm: boolean) => void;
  onOnlyCLose?: (closeForm: boolean) => void;
}

class HoursForm extends React.PureComponent<HoursFormProps & RouteComponentProps> {

  generateProjectList = async () => {
    const res = await ApiGetProject({
      page_size: 99999,
      platform: 1, // 选择项目不能选择归档的项目
      filter: 1
    })
    this.setState({
      projectList: res.data
    })
  }

  state = {
    hoursList: HOUR_LIST,
    projectList: [],
    dialogProps: {
      title: '',
      projectIndex: 1,
      currentTimestamp: 0,
      totalHours: 0,
      workHoursForm: [] as any[],
      todayHoursTotal: 0
    },
    toast: {
      tip: '',
      visibled: false
    },
    workHourList: []
  }

  componentDidMount () {
    this.generateProjectList().then(res => {
      if (this.props.visibled) {
        this.fillHoursForm(this.props.visibled, this.props.formData)
      }
    })
  }

  componentDidUpdate (prevProps: any) {
    if (this.props.visibled !== prevProps.visibled && this.props.visibled) {
      this.fillHoursForm(this.props.visibled, this.props.formData)
    }
  }

  getWorkHoursList = async (timestamp: any) => {
    const workHoursResult = await ApiGetWorkHoursList({
      date_param: timestamp
    })
    this.setState({
      workHourList: workHoursResult.data
    })
  }

  // UNSAFE_componentWillReceiveProps (nextProps: { visibled: boolean; formData: any }) {
  //   // let { dialogProps } = this.state
  //   console.log(nextProps.visibled);
  //   if (nextProps.visibled) {
  //     this.fillHoursForm(nextProps.visibled, nextProps.formData)
  //   } else {
  //     // dialogProps.showDialog = nextProps.visibled
  //     // this.setState({
  //     //   dialogProps
  //     // })
  //   }
  // }


  get projects () {
    let { projectList } = this.state
    // return projectList.filter(item => canAddDetail(item.start_time, item.end_time, dayjs(dialogProps.currentTimestamp * 1000)))
    if (projectList.length === 0) {
      let jump = [
        {
          id: -999,
          name: `${$i18n.$t('pc-no-project-desc')}`
        }
      ]
      return jump
    }
    return projectList
  }

  /** 填充表单 */
  fillHoursForm = (visibled: any, dayDetail: any) => {
    let { dialogProps, hoursList } = this.state
    let projectIndex = this.projects.findIndex((project) => project.id === dayDetail.projectId)
    if (dayDetail.todayHoursTotal) {
      dialogProps.todayHoursTotal = dayDetail.todayHoursTotal
    }
    dialogProps.currentTimestamp = dayDetail.currentTimestamp
    dialogProps.title = dayDetail.title
    // dialogProps.showDialog = visibled
    dialogProps.projectIndex = projectIndex
    dialogProps.totalHours = dayDetail.hoursDetail.reduce((total: any, item: { working_hour: any }) => total += item.working_hour, 0)
    this.getWorkHoursList(dayDetail.currentTimestamp)
    if (dayDetail.hoursDetail.length > 0) {
      dialogProps.workHoursForm = dayDetail.hoursDetail.map((item: { working_hour: any; id: any; status: any; remark: any }, index: any) => {
        let hourIndex = hoursList.findIndex(hour => hour.label === `${item.working_hour}${$i18n.$t('work-hours-unit')}`)
        return {
          id: item.id,
          working_hours_id: item.id,
          projectHours: `${item.working_hour}${$i18n.$t('work-hours-unit')}`,
          working_hour: item.working_hour,
          formId: index,
          isComplete: item.status || false,
          showTextArea: false,
          projectName: projectIndex !== -1 ? this.projects[projectIndex].name : '',
          project_id: dayDetail.projectId,
          projectDesc: item.remark || '',
          remark: item.remark || '',
          projectIndex: projectIndex,
          hourIndex: hourIndex
        }
      })
    } else {
      dialogProps.workHoursForm = [
        {
          formId: 0,
          project_id: dayDetail.projectId,
          projectName: projectIndex !== -1 ? this.projects[projectIndex].name : '',
          projectIndex: projectIndex,
          projectHours: 0,
          working_hour: 1,
          remark: '',
          projectDesc: '',
          showTextArea: false,
          hourIndex: 1
        }
      ]
    }
    this.setState({
      dialogProps
    })
  }

  /** 校验表单 */
  checkForm (form: { projectName: any; working_hour: any }) {
    let flag = true
    if (!form.projectName) {
      message.warning($i18n.$t('work-hours-project-name'))
      flag = false
      return
    }
    if (!form.working_hour) {
      message.warning($i18n.$t('alert-select-time'))
      flag = false
      return
    }
    return flag
  }

  /** 添加工时 */
  async saveWorkHours () {
    let { dialogProps, workHourList } = this.state
    let todayWorkHour = workHourList.reduce((total, item: any) => total += item.working_hour, 0)
    let updateWorkTotal = dialogProps.workHoursForm.filter(i => i.working_hours_id).reduce((total, item) => total += item.working_hour, 0)
    let createWorkTotal = dialogProps.workHoursForm.filter(i => !i.working_hours_id).reduce((total, item) => total += item.working_hour, 0)
    let total = 0
    if (updateWorkTotal > 0) {
      let cutHour = 0
      dialogProps.workHoursForm.forEach(form => {
        workHourList.forEach((work: any) => {
          if (work.id === form.working_hours_id) {
            cutHour += work.working_hour
          }
        })
      })
      total = todayWorkHour - cutHour + updateWorkTotal
    } else {
      total = todayWorkHour + createWorkTotal
    }
    if (total > 24) {
      message.warning($i18n.$t('can-not-over-24'))
    } else {
      let params: any[] = dialogProps.workHoursForm.map((item) => {
        if (item.working_hours_id) {
          return {
            working_hours_id: item.id,
            project_id: item.project_id,
            working_hour: item.working_hour,
            remark: item.projectDesc,
            work_date: dialogProps.currentTimestamp
          }
        } else {
          return {
            project_id: item.project_id,
            working_hour: item.working_hour,
            remark: item.projectDesc,
            work_date: dialogProps.currentTimestamp
          }
        }
      })
      let updateForm = params.filter(i => i.working_hours_id)
      let createForm = params.filter(i => !i.working_hours_id)
      if (createForm.length > 0) {
        if (this.checkForm(dialogProps.workHoursForm[0])) {
          let createRes = await ApiPostCreateWorkHours({ working_hours_item: JSON.stringify(createForm) })
          if (createRes.retCode === 0) {
            message.success($i18n.$t('save-success'))
            setTimeout(() => {
              this.props.onFetchData && this.props.onFetchData(false)
            }, 800)
          }
        }
      }
      if (updateForm.length > 0) {
        let updateRes = await ApiPutUpdateWorkHours({ working_hours_item: JSON.stringify(updateForm) })
        if (updateRes.retCode === 0) {
          message.success($i18n.$t('save-success'))
          setTimeout(() => {
            this.props.onFetchData && this.props.onFetchData(false)
          }, 800)
        }
      }
    }
  }

  handleDeleteHour = (hourId: any) => {
    let _this = this
    Modal.confirm({
      title: `${$i18n.$t('work-hours-tips')}`,
      content: `${$i18n.$t('work-hours-sure-delete')}`,
      okText: `${$i18n.$t('delete')}`,
      cancelText: `${$i18n.$t('cancel')}`,
      onOk: async (res) => {
        await ApiDeleteOneWorkHours({
          id: hourId
        })
        setTimeout(() => {
          _this.props.onFetchData && _this.props.onFetchData(false)
        }, 800)
      }
    })
  }

  render () {
    const { dialogProps, hoursList, projectList } = this.state
    return (
      <div>
        <BaseDialog
          visibled={this.props.visibled}
          customFooter={true}
          width={412}
          height={293}
          onIconClose={(val: any) => {
            this.props.onOnlyCLose && this.props.onOnlyCLose(false)
          }}
          title={dialogProps.title}
        >
          <div className="hour-container">
            {
              projectList.length === 0 &&
              <div style={{ textAlign: 'center', padding: '20px 0' }}>
                <Spin spinning tip="加载中" />
              </div>
            }
            {
              dialogProps.workHoursForm.map((form, index) => (
                <div className="horus-form" key={'horus-form' + index}>
                  {
                    form.working_hours_id &&
                    <div className="delete-link">
                      <div
                        className="delete-text base-hover"
                        onClick={() => {
                          this.handleDeleteHour(form.working_hours_id)
                        }}
                      >{$i18n.$t('delete')}</div>
                    </div>
                  }
                  <div className="control project">
                    <div className="icon">
                      <i className="icon-font">&#xe665;</i>
                    </div>
                    <div className="required">*</div>
                    <div className="selector-box">
                      <SelectorPicker
                        placeholder={$i18n.$t('work-hours-project-name')}
                        options={projectList}
                        rcKey={'id'}
                        valueKey={'id'}
                        labelKey={'name'}
                        defaultValue={form.project_id}
                        onChange={(projectId: any) => {
                          if (projectId === -999) {
                            this.props.history.push('/project-manager')
                          } else {
                            const selectProject = this.projects.find(p => p.id === projectId)
                            if (selectProject) {
                              // dialogProps.workHoursForm[index].projectIndex = selectIndex
                              dialogProps.workHoursForm[index].projectName = selectProject.name
                            }
                            dialogProps.workHoursForm[index].project_id = projectId
                            this.setState({
                              dialogProps
                            })
                          }
                        }}
                      ></SelectorPicker>
                    </div>
                  </div>
                  <div className="control hours">
                    <div className="icon">
                      <i className="icon-font">&#xe666;</i>
                    </div>
                    <div className="required">*</div>
                    <div className="selector-box">
                      <SelectorPicker
                        placeholder={$i18n.$t('work-hours-number')}
                        options={hoursList}
                        rcKey={'value'}
                        valueKey={'value'}
                        labelKey={'label'}
                        defaultValue={form.working_hour}
                        onChange={(h: any) => {
                          // dialogProps.workHoursForm[index].hourIndex = selectIndex
                          // dialogProps.workHoursForm[index].projectHours = h
                          dialogProps.workHoursForm[index].working_hour = h
                          dialogProps.totalHours = dialogProps.workHoursForm.reduce((total, item) => total += item.working_hour, 0)
                          this.setState({
                            dialogProps
                          })
                        }}
                      ></SelectorPicker>
                    </div>
                  </div>
                  <div className="control desc">
                    <div className="icon">
                      <i className="icon-font">&#xe663;</i>
                    </div>
                    <div className="required"></div>
                    <div className="input-box">
                      <textarea
                        className="custom-input-class"
                        placeholder={$i18n.$t('work-hours-detail')}
                        value={form.projectDesc}
                        onChange={(e: any) => {
                          const value = e.target.value
                          const formVal = { ...form }
                          formVal.projectDesc = value
                          formVal.remark = value
                          const formData = { ...dialogProps }
                          formData.workHoursForm[index] = formVal
                          formData.workHoursForm[index] = formVal
                          this.setState({
                            dialogProps: formData
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="footer">
            <div className="total-hours">
              <span className="">{`${$i18n.$t('total-time')}：${dialogProps.totalHours}${$i18n.$t('work-hours-unit')}`}</span>
            </div>
            <div className="save">
              <BaseButton customType="primary" onClick={() => {
                this.saveWorkHours()
              }}>{$i18n.$t('work-hours-save')}</BaseButton>
            </div>
          </div>
        </BaseDialog>
      </div>
    )
  }
}

export default withRouter(HoursForm)
