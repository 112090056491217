/**
 * 个人工时
 */
import { observable, action, makeAutoObservable, runInAction } from 'mobx';
import { ApiGetStatistics, ApiPCGetStatistics } from '@/api'
import dayjs, { Dayjs } from 'dayjs'
import { fillDate, generateQuickDate } from '@/utils/date'
import UserStore from '@/store/user';
import groupBy from 'lodash/groupBy'

function generateTableData (projects: any[]) {
  let tableTitle = {
    userId: UserStore.userInfo?.id,
    name: UserStore.userInfo?.name || UserStore.userInfo?.nickname,
    totalHours: projects.reduce((total, item) => total + item.total_working_hours, 0),
    avatarUrl: UserStore.userInfo?.head_img_url
  }
  return {
    tableTitle
  }
}

function generateWeekData (data: { [x: string]: any; }, range: dayjs.Dayjs[]) {
  const works = Object.keys(data).map(k => {
    return {
      day: dayjs(+k * 1000),
      hoursDetail: data[k]
    }
  })
  let weekDetail = fillDate(range[0], range[1]).map(day => {
    const find = works.find(w => w.day.isSame(day, 'd'))
    if (!find) return { day, hoursDetail: [] }
    return {
      day,
      hoursDetail: find.hoursDetail
    }
  })
  return weekDetail
}

function convertResDataToTable (projects: any[]) {
  return projects.map((project: any) => {
    const workHourGroup = groupBy(project.working_hours.map((item: any) => {
      return {
        ...item,
        work_date: dayjs.unix(item.work_date).startOf('d').unix()
      }
    }), 'work_date')
    return {
      project: Object.keys(project).reduce((obj: any, key) => {
        if (key !== 'working_hours') obj[key] = project[key]
        return obj
      }, {}) as HourTableProject,
      user: {
        id: UserStore.userInfo?.id || 0,
        name: UserStore.userInfo?.name || UserStore.userInfo?.nickname || '',
        avatar: UserStore.userInfo?.head_img_url || ''
      },
      hours: Object.keys(workHourGroup).map((dateKey) => {
        return {
          workHours: workHourGroup[dateKey],
          hour: workHourGroup[dateKey].reduce((count, item) => count + item.working_hour, 0),
          date: dayjs.unix(Number(dateKey))
        }
      })
    }
  })
}

class Self {
  dateRange: Dayjs[] = generateQuickDate().week
  selfData: any = {
    tableTitle: {
      userId: 0,
      name: '',
      totalHours: 0,
      avatarUrl: ''
    }
  }
  weekData: any[] = []
  hourDatas: HourTableRow[] = []
  projectDatas: ProjectDataItem[] = []

  constructor() {
    makeAutoObservable(this, {
      dateRange: observable,
      selfData: observable,
      weekData: observable,
      hourDatas: observable,
      projectDatas: observable,
      changeDateRange: action,
      getSelfData: action,
      fetchProjectData: action
    })
  }

  async getSelfData () {
    const [start, end] = this.dateRange
    const res = await ApiGetStatistics({
      start_date: start.unix(),
      end_date: end.endOf('day').unix()
    })

    if (res) {
      runInAction(() => {
        this.hourDatas = convertResDataToTable(res.data)
        this.selfData = generateTableData(res.data)
        this.weekData = generateWeekData(res.date_group, this.dateRange)
      })
    }
  }

  async fetchProjectData() {
    const [start, end] = this.dateRange
    const res = await ApiPCGetStatistics({
      start_date: start.unix(),
      end_date: end.endOf('day').unix()
    })

    if (res) {
      runInAction(() => {
        this.projectDatas = res.data.project_data
      })
    }
  }

  changeDateRange (range: dayjs.Dayjs[]) {
    this.dateRange = range
    return Promise.all([this.getSelfData(), this.fetchProjectData()])
  }
}
export default new Self()
