import React from 'react'
import './index.scss'
import { $i18n } from '@/lang'
import { generateQuickDate } from '@/utils/date'
import Self from '@/store/self'
import DateSelect from '@/components/date-select'
import HoursColor from '@/components/hours-color'
import HoursTable from '@/components/hours-table'
import HoursForm from '@/components/hours-form'
import { observer } from 'mobx-react'
import UserStore from '@/store/user'
import { createRef } from 'react'
import { getColor, colors } from '@/components/charts/config'
import { RouteComponentProps, withRouter } from 'react-router-dom'
// import { Chart } from '@antv/g2'

@observer
class WorkHours extends React.Component<RouteComponentProps> {

  i18nTitle = $i18n.$t('app-name')

  pieRef = createRef<HTMLDivElement>()

  state = {
    hideProject: true, // 合并10个以上项目到其他
    hoursEditForm: {
      visibled: false,
      form: {
        title: '',
        currentTimestamp: 0,
        projectId: 0,
        hoursDetail: []
      }
    },
    dateRange: generateQuickDate().week
  }

  get projectList () {
    if (!this.state.hideProject || Self.projectDatas.length <= 6) return Self.projectDatas
    let data: ProjectDataItem[] = Self.projectDatas.slice(0, 5).concat(
      Self.projectDatas.slice(5).reduce((count: ProjectDataItem, item: ProjectDataItem) => {
        count.Proportion += item.Proportion
        count.total_working_hours += item.total_working_hours
        count.member_count += item.member_count
        return count
      }, {
        color: '#FE80EC',
        project_name: $i18n.$t('other-projects'),
        total_working_hours: 0,
        member_count: 0,
        Proportion: 0,
        project: -1,
        start_time: 0,
        end_time: 0,
        created_at: 0,
        creator: '',
        status: 0,
        project_all_hours: 0,
        pre_working_hours: null,
        working_hours_count: 0,
        user_data: {
          user: []
        }
      })
    )

    if (data.length === 1 && data[0].project === -1) data = []
    return data
  }

  get pieChartData () {
    return this.projectList.map(item => ({
      value: item.Proportion,
      label: item.project_name,
      color: item.color,
      work_hours: item.total_working_hours
    }))
  }

  componentDidMount () {
    document.title = this.i18nTitle
    Self.getSelfData()
    this.fetchProjectData()
  }

  fetchProjectData () {
    Self.fetchProjectData().then(() => {
      this.renderChart()
    })
  }

  chart: G2.Chart | null = null

  renderChart() {
    if (this.pieRef.current) {
      if (this.chart === null) {
        this.chart = new G2.Chart({
          container: this.pieRef.current,
          width: 460,
          height: 380,
          padding: [20, 20, 20, 50],
          renderer: 'canvas'
        })
      }
      
      this.chart.data(this.pieChartData)

      this.chart.coordinate('theta', {
        radius: 0.75,
        innerRadius: 0.6
      })

      this.chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}h</li>',
      })

      this.chart.annotation().text({
        position: ['50%', '50%'],
        content: (data) => {
          const total = Math.floor(data.reduce((count: number, item: any) => {
            return count + (item.work_hours || 0)
          }, 0))
          return total + 'h'
        },
        animate: false,
        style: {
          fontSize: 32,
          fontWeight: 'bold',
          fill: '#8c8c8c',
          textAlign: 'center'
        }
      })

      // @ts-ignore
      this.chart.interval().adjust('stack').position('work_hours').color('label', colors)

      this.chart.render()
    }
  }

  render () {
    const { hoursEditForm } = this.state
    return (
      <div className="work-hours base-page">
        <div className="work-hours-header">
          <div className="header-container">
            <div className="date-selector">
              <DateSelect
                onChange={(range) => {
                  Self.changeDateRange(range).then(() => this.renderChart())
                }}
                defaultValue={Self.dateRange.map(i => i.unix() * 1000)}
              ></DateSelect>
            </div>
            {/* <div className="right-export base-hover">
              <span>导出</span>
            </div> */}
          </div>
        </div>
        <div className="work-hours-container">
          <div>
            <div className="table">
              <div className="table-container">
                <div className="title-info">
                  <div className="info-container">
                    <div className="left">
                      <div className="user">
                        <div className="avatar">
                          <img
                            className="img"
                            src={`${UserStore.userInfo?.head_img_url}`}
                            alt=""
                          ></img>
                        </div>
                        <div className="name"><span>{`${UserStore.userInfo?.name || UserStore.userInfo?.nickname}`}</span></div>
                      </div>
                      <div className="current-total">
                        {`${$i18n.$t('total-time-during-this-period')}：${Self.selfData.tableTitle.totalHours}h`}
                      </div>
                    </div>
                    <div className="right">
                      <HoursColor></HoursColor>
                    </div>
                  </div>
                </div>
                <HoursTable
                  onBubbleCLick={(dayDetail: { title: string; currentTimestamp: number; projectId: number; hoursDetail: never[]; }) => {
                    hoursEditForm.visibled = true
                    hoursEditForm.form = dayDetail
                    this.setState({
                      hoursEditForm
                    })
                  }}
                  showAddRow={true}
                  dateRange={Self.dateRange}
                  datas={Self.hourDatas}
                ></HoursTable>
              </div>
            </div>
            {/* <div className="desc">
              <div className="desc-container">
                {/* <WorkHoursDesc weekData={Self.weekData}></WorkHoursDesc> */}
            {/* </div> */}
            {/* </div>  */}

            <div className="data-pie-chart">
              <div className="my-data-title">{`${$i18n.$t('i-data-distribution')}`}</div>
              <div className="data-pie-chart-box">
                <div className="pie-container">
                  <div ref={this.pieRef}></div>
                </div>
                <div className="project-table">
                  <div className="header row">
                    <div className="item name">{$i18n.$t('project-name')}</div>
                    <div className="item">%</div>
                    <div className="item">
                      <i className="icon-font">&#xe603;</i>
                    </div>
                    <div className="item">
                      <i className="icon-font">&#xe624;</i>
                    </div>
                  </div>
                  <div className="tbody">
                    {
                      this.projectList.map((item, index) =>
                        <div
                          className="row project-cell"
                          key={`project_${index}`}
                          onClick={() => {
                            if (item.project === -1) {
                              this.setState({
                                hideProject: false
                              })
                            } else {
                              this.props.history.push(`/home/project/${item.project}?start=${Self.dateRange[0].unix()}&end=${Self.dateRange[1].unix()}`)
                            }
                          }}
                        >
                          <div className="item name">
                            <div className="color-block" style={{
                              backgroundColor: getColor(index)
                            }}></div>
                            <div className="name-val">
                              {item.project_name}
                            </div>
                          </div>
                          <div className="item">{Math.round(item.Proportion * 100)}%</div>
                          <div className="item">{item.total_working_hours}h</div>
                          <div className="item">{item.member_count}</div>
                        </div>
                      )
                    }
                  </div>
                </div>
                {
                  this.projectList.length === 0 &&
                  <div className="empty-data">{$i18n.$t('no-data')}</div>
                }
              </div>
            </div>
          </div>



          {
            hoursEditForm.visibled &&
            <HoursForm
              visibled={hoursEditForm.visibled}
              formData={hoursEditForm.form}
              onFetchData={(close: boolean) => {
                hoursEditForm.visibled = close
                this.setState({
                  hoursEditForm
                })
                Self.getSelfData()
                this.fetchProjectData()
              }}
              onOnlyCLose={(close: boolean) => {
                hoursEditForm.visibled = close
                this.setState({
                  hoursEditForm
                })
              }}
            />
          }
        </div>
      </div>
    )
  }
}

export default withRouter(WorkHours)