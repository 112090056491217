import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Raven from 'raven-js'

if (process.env.NODE_ENV === 'production') {
  Raven.config('https://e7014a8f1faa483cbe5d6dff369c5f88@sentry.xiliutech.com/42', {
    environment: process.env.REACT_APP_BUILD_ENV || 'unknow'
  }).install()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
