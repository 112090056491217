import React, { useMemo, useState } from 'react'
import './index.scss'
import { observer } from 'mobx-react'
import ContactPickerModal from './modal/index'
import unionBy from 'lodash/unionBy'

interface ContactPickerProps {
  value?: API.SearchUserInfo[];
  onChange?: (value: API.SearchUserInfo[]) => void;
  /** 常驻显示用户【不能被删除】 */
  fixedUsers?: API.SearchUserInfo[]
}

const ContactPicker = (props: ContactPickerProps) => {
  const {
    value = []
  } = props

  const changeProp = (data: API.SearchUserInfo[]) => {
    props.onChange && props.onChange(data)
  }

  const [isModalVisible, changeIsModalVisible] = useState(false)

  /** 显示的用户列表，带固定用户 */
  const showUser = useMemo(() => {
    return unionBy((props.fixedUsers || []).concat(value), 'openid')
  }, [props.fixedUsers, value])

  return (
    <div className="base-contact-picker">
      <div className="contact-picker-container" onClick={() => changeIsModalVisible(true)}>
        {
          showUser.map(user =>
            <div className="user" key={'user' + user.id}>
              <div className="avatar-content">
                <img alt="" className="avatar" src={user.head_img_url}></img>
              </div>
              <span className="name">{user.name}</span>
            </div>
          )
        }
        <div className="add">
          <i className="icon-font">&#xe641;</i>
        </div>
      </div>
      <ContactPickerModal
        visible={isModalVisible}
        requestClose={() => changeIsModalVisible(false)}
        onChange={(data) => {
          changeProp(data)
        }}
        fixedUsers={props.fixedUsers}
        defaultSelect={value}
      />
    </div>
  )
}

ContactPicker.defaultProps = {
  defaultContacts: [],
  ignore: false
}

export default observer(ContactPicker)
