import React from 'react'
import './index.scss'
import { $i18n } from '@/lang'
import { message } from 'antd'
import SelectorPicker from '../selector-picker'

interface PaginationProps {
  current: number;
  size: number;
  total: number;
  sizes?: number[];
  onSizeChange?: (size: number) => void;
  onChange?: (page: number) => void;
}

const Pagination: React.SFC<PaginationProps> = (props) => {
  const { sizes = [10, 15, 30] } = props
  const pageTotal = Math.ceil(props.total / props.size)
  const pages = Array.from({ length: pageTotal }).fill(0)
  return (
    <div className="pagination">
      <div className="total">{$i18n.$t('list-total')} {pageTotal} {$i18n.$t('list-page')}</div>
      <SelectorPicker
        style={{width: '150px'}}
        options={sizes.map(i => {
          return {
            label: `${i} ${$i18n.$t('list-item')} / ${$i18n.$t('list-page')}`,
            value: i
          }
        })}
        rcKey={'value'}
        valueKey="value"
        defaultValue={props.size}
        onChange={(value: any) => {
          props.onSizeChange && props.onSizeChange(value)
        }}
      ></SelectorPicker>
      <div className="pages">
        {
          pages.map((_, i) => {
            return (
              <div
                key={'pages' + i}
                className={`item ${1 + i === props.current && 'active'}`}
                onClick={() => {
                  const page = i + 1
                  if (page === props.current) return
                  props.onChange && props.onChange(page)
                }}
              >{1 + i}</div>
            )
          })
        }
      </div>
      <div className="jump">
        <div className="label">
          {$i18n.$t('list-go')}
        </div>
        <input
          value={String(props.current)}
          type="number"
          className="page-input"
          onChange={(e: any) => {
            const page = Number(e.target.value)
            if (page <= 0 || page > pageTotal) {
              message.error($i18n.$t('alert-error-page'))
              return String(props.current)
            }
            props.onChange && props.onChange(page)
          }}
        ></input>
        {$i18n.$t('list-page')}
      </div>
    </div>
  )
}

export default Pagination
