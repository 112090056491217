import React from 'react'
import './index.scss'

import HourTips from '@/components/hour-tips'
import { $i18n } from '@/lang'
import { TIPS_LIST } from '@/utils/constants'

const HoursColor = () => {
  return (
    <div className="hour-color">
      <div className="hour-color-container">
        {
          TIPS_LIST.map((item, index) => (
            <div
              className="item base-hover"
              key={'hour-color' + index}
              style={{ backgroundColor: `${item.color}` }}
            >
              <div className="box">
                <HourTips tip={`${item.tip}`}></HourTips>
              </div>
            </div>
          ))
        }
      </div>
      <div className="tips-desc">
        <div>{$i18n.$t('color-tips-less')}</div>
        <div>{$i18n.$t('color-tips-more')}</div>
      </div>
    </div>
  )
}

export default HoursColor
