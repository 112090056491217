import React from 'react'
import './index.scss'

interface HourTipsProps {
  tip: string
}

const HourTips: React.FC<HourTipsProps> = (props) => {
  return (
    <div className="hour-tips">
      <div className="hour-tips-container">
        <div className="tips-text">
          {props.tip}
          <div className="triangle-container">
            <div className="triangle"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HourTips
