import React from 'react'
import './index.scss'

interface PopoverProps {
  visible?: boolean;
  // @ts-ignore
  onChange?: ({visible: boolean}) => void;
  position?: {
    x: number;
    y: number;
  }
}

const Popover: React.SFC<PopoverProps> = (props) => {
  return (
    <div className={`popover ${props.visible && 'visible'}`}
      onClick={() => {
        props.onChange && props.onChange({
          visible: false
        })
      }}
    >
      <div className="container" style={{
        top: props.position && props.position.y + 'px',
        left: props.position && props.position.x + 'px'
      }}>
        {props.children}
      </div>
    </div>
  )
}

Popover.defaultProps = {
  position: {
    x: 0,
    y: 0
  }
}

export default Popover
