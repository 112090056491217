const zh_CN_package: Lang = {
  'hour': '小时',
  'works': '工时',
  'project': '项目',
  'data': '数据',
  'set': '设置',
  'create-project': '新建项目',
  'edit-project': '编辑项目',
  'create-work-hours': '新建工时',
  // 晒成就
  'show-achievement': '晒成就',
  'blank-text': '暂无工时',
  'work-hours': '工时',
  'work-hours-spend-time': '总时长',
  'work-hours-each-represents': '每一格代表0.5h',
  'work-hours-projects': '项目',
  'work-hours-optional-project': '点击标记时间',
  'work-hours-projects-list': '添加项目',
  'work-hours-cancel': '取消选择',
  'work-hours-project-selected': '已选择',
  'work-hours-delete': '删除记录',
  'work-hours-add-details': '添加备注',
  'work-hours-detail': '请添加备注',
  'work-hours-tips': '提示',
  'work-hours-overwritten': '重选之后时间块上的内容将被覆盖掉哦！',
  'work-hours-continue': '继续',
  'work-hours-sure-delete': '确定删除当前的时间记录吗？',
  // 移动端工时
  'no-work-add-project': '暂无可用项目，快去添加项目吧',
  'can-not-blank': '工作备注不能为空',
  'work-hours-cell-desc-total': '总计/小时',
  'mobil-hours-details': '工作备注',
  'can-not-over-24': '添加时长一天内不能大于24小时',
  'should-select-time': '请先选择时间',
  'pc-confirm': '确认',
  'pc-bubble-edit': '编辑',
  'pc-bubble-add': '添加',
  'work-hours-add-title': '添加工时',
  'require-time': '所需时长',
  'work-hours-project-name': '请选择项目名称（必填）',
  'work-hours-number': '请选择所需时长（必填）',
  'work-hours-unit': '小时',
  'work-hours-save': '保存',
  'work-hours-message-remind': '工作日志记录提醒',
  'work-hours-working-remind': '提醒时间',
  'work-hours-forgotten-remind': '工作日志遗忘提醒',
  'work-hours-forgotten-remind-time': '提醒时间',
  'work-hours-working-remind-default': '09:00',
  'work-hours-worked-remind-default': '18:00',
  'work-hours-forgotten-remind-time-default': '17:00',
  'loading': '加载中...',
  'now': '至今',
  'input-project-name': '请输入（不超过100个字符）',
  'input-project-pre-hours': '请设置预算工时',
  'add-project': '新建项目',
  'start-time': '开始时间',
  'end-time': '截止时间',
  'data-posting': '数据提交中...',
  'archived': '已归档',
  'project-total': '项目总计/个',
  'hour-total': '记录时数/小时',
  'member-total': '填报工时人数/人',
  'project-list': '项目列表',
  'project-name': '项目名称',
  'project-work-hour': '累计用时/小时',
  'project-member': '项目成员/人',
  'archive-title': '本项目将被存档，存档之后将无法继续记录时间',
  'archive-content': '存档后将发送消息通知项目成员',
  'empty-project': '暂无项目',
  'empty-data': '暂无数据',
  'placeholder-input': '请输入',
  'placeholder-select': '请选择',
  'add-participants': '添加项目成员',
  'mark-color': '标记项目颜色',
  'cancel': '取消',
  'save': '保存',
  'save-success': '保存成功',
  'total-time-this-week': '本周工时总计',
  'total-work-hours': '记录总计/小时',
  'time-h': '工时/小时',
  'time-condition-of-everyday': '每日总计',
  'time-detail-of-everyday': '每日工作详情',
  'add-hours-detail': '添加工作备注',
  'edit-hours-detail': '编辑工作记录',
  'watch-more-data-in-pc': '更多数据请在PC端查看',
  'guide-next': '下一步',
  'guide-finish': '知道了',
  'network-retry': '返回重试',
  'network-error': '网络错误',
  'network-error-label': '网络离家出走了',
  'empty-task-day': '暂无工作记录，',
  // PC
  'color-tips-less': '少',
  'color-tips-more': '多',
  'i-join-project': '参与项目（个）',
  'i-record-hours': '记录工作时长（小时）',
  'i-data-distribution': '我的工时数据分布',
  'i-joined-project': '我参与的项目',
  'pc-no-project-desc': '暂无项目，先去新建项目吧',
  'pro-tips': '当前版本不支持数据权限分级，Pro版本尽情期待',
  // 数据分析
  'data-analyze': '数据分析',
  'this-week': '当前一周',
  'last-two-weeks': '当前两周',
  'this-month': '当前一月',
  'cumulative-time': '累计工时',
  'budget-time': '预算工时',
  'time-of-day': '本时段工时',
  'total': '合计',
  'total-time': '总计工时',
  'project-creator': '项目创建人',
  'project-creation-time': '项目创建时间',
  'start-and-end-time': '项目起止时间',
  'archive': '存档',
  'cancel-archive': '取消存档',
  'no-data': '暂无数据',
  'other-projects': '其他',
  // 人员概况
  'team-overview': '团队',
  'all-staff': '全部人员',
  'total-time-during-this-period': '本时段工时总计',
  // 项目管理
  'project-management': '项目',
  'project-status': '项目状态',
  'project-all': '全部项目',
  'project-archived': '已存档',
  'project-not-archive': '进行中',
  'add-project-members': '添加项目成员',
  'select-project-color': '标记项目颜色',
  'cumulative-time-budget-time': '累计工时/预算工时',
  'operation': '操作',
  'no-project': '暂无项目',
  'list-total': '共',
  'list-page': '页',
  'list-item': '条',
  'list-go': '前往',
  'reset': ' 重置',
  'delete': '删除',
  'update-success': '更新成功',
  'create-success': '创建成功',
  'alert': '提示',
  'confirm-delete': '确定删除项目',
  'forever-del': '该项目及项目下所有记录也将永久删除。',
  'alert-confirm': '确定',
  'delete-success': '删除成功',
  'alert-project-name': '请输入项目名称',
  'alert-error-time': '起始时间不能大于结束时间',
  'alert-project-color': '请标记项目颜色',
  'alert-error-page': '请输入正确页码',
  //工时记录
  'daily-log': '日志',
  'details': '工时备注',
  'alert-select-time': '请选择所需时长',
  'have-accumulated-records': '你已累计记录',
  'records-days': '天啦！',
  'show-your-achieve': '快去晒晒你的新成就吧！',
  'show-off': '炫耀一下',
  // 设置
  'help-and-feedback': '意见反馈',
  'modal-title': '欢迎使用维拉日志',
  'body-using': '在您使用期间，如有任何疑问，欢迎积极反馈，反馈邮箱',
  'body-do-better': '有了您的参与，我们会做的更好。',
  'i-known': '知道啦',
  'app-name': '维拉日志',
  'close-remind': '关闭提醒',
  'save-image': '保存图片',
  // App更新
  'app-update-title': '更新提示',
  'app-update-content': '新版本已经准备好，是否重启应用？',
  // 请求
  'request-timeout': '请求超时',
  'request-fail': '服务器错误',
  'export': '导出',
  'exporting': '正在导出表格',
  // 微信独有
  'share-slogan': '轻量级日志记录工具',
  'wx-works': '日志',
  'wx-data': '数据',
  'wx-work-hours': '日志',
  'wx-project': '标签',
  'wx-project-list': '标签列表',
  'wx-edit-hours-detail': '编辑日志',
  'wx-work-hours-projects-list': '添加标签',
  'wx-no-work-add-project': '暂无可用标签，快去添加标签吧',
  'wx-create-project': '新建标签',
  'wx-edit-project': '编辑标签',
  'wx-budget-time': '预算时长',
  'wx-empty-project': '暂无标签',
  'wx-cumulative-time': '累计时长',
  'wx-total-time-this-week': '本周时长总计',
  'wx-time-detail-of-everyday': '每日记录详情',
  'wx-add-hours-detail': '添加备注',
  'wx-empty-task-day': '暂无记录，',
  'wx-work-hours-add-title': '添加记录',
  'wx-project-name': '标签名称',
  'wx-mobil-hours-details': '备注',
  'wx-confirm-delete': '确定删除标签',
  'wx-forever-del': '该标签及标签下所有记录也将永久删除。',
  'wx-mine': '我的',
  'wx-no-name': '未设置',
  'wx-sun-achievement': '晒成就',
  'wx-cumulative-record-rate': '连续记录天数',
  'wx-cumulative-record-days': '记录天数',
  'wx-cumulative-record-time': '记录时长',
  'wx-cumulative-record-total': '记录次数',
  'wx-remind-set': '提醒设置',
  'wx-record-reminder-time': '记录提醒时间',
  'wx-forgotten-reminder-time': '遗忘提醒时间',
  'wx-recommend-to-friends': '推荐维拉日志给好友'
}

export default zh_CN_package