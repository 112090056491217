import React from 'react'
import './index.scss'
import dayjs, { Dayjs } from 'dayjs'
import groupBy from 'lodash/groupBy'
import { getWeek, $i18n } from '@/lang'
import isEqualWith from 'lodash/isEqualWith'
import { generateQuickDate } from '@/utils/date'

import BaseButton from '@/components/base-button'

interface DateSelectProps {
  defaultValue?: number[];
  onChange?: (value: Dayjs[]) => void;
}

class DateSelect extends React.Component<DateSelectProps> {
  state = {
    standard: dayjs(),
    select: generateQuickDate().week,
    picker: false, // 是否显示时间选择
    value: generateQuickDate().week
  }
  get calendars () {
    return [
      this.state.standard,
      this.state.standard.add(1, 'month')
    ]
  }

  get calendarData () {
    return this.calendars.map((item) => ({
      key: `calendar_${item.format('YYYY-MM')}`,
      dates: this.selectData(item.unix() * 1000)
    }))
  }

  get valueStr () {
    const [start, end] = this.state.value
    if (start && end) {
      return `${start.format('MM.DD')} - ${end.format('MM.DD')}`
    }
    return '-'
  }

  get quickDate () {
    return generateQuickDate()
  }

  /**
   * 时间选择高亮
   */
  get checkSelectStatus () {
    const equalDayjs = (a: Dayjs[], b: Dayjs[]) => {
      if (a.length === 0 || b.length === 0) return false
      if (a.length !== b.length) return false
      for (let i = 0; i < a.length; i++) {
        if (!a[i].isSame(b[i], 'd')) return false
      }
      return true
    }
    return {
      week: isEqualWith(this.state.value, this.quickDate.week, equalDayjs),
      twoweek: isEqualWith(this.state.value, this.quickDate.twoweek, equalDayjs),
      month: isEqualWith(this.state.value, this.quickDate.month, equalDayjs)
    }
  }

  selectData (center: number) {
    let start = dayjs(center).startOf('month').startOf('week').add(1, 'd')
    const end = dayjs(center).endOf('month').endOf('week').add(1, 'd')

    const datas: Dayjs[] = []
    while (start.isBefore(end)) {
      datas.push(start)
      start = start.add(1, 'd')
    }
    let startWeek = 0, endWeek = 0
    const groups = groupBy(datas, (item) => {
      let current = dayjs(center)
      let week = endWeek
      if (item.isBefore(current, 'month')) {
        week = startWeek
      } else if (item.isAfter(current, 'month')) {
        week = endWeek
      } else {
        if (item.day() === 0) { // MARK: 一周初始化为周一
          endWeek += 1
        }
      }

      return week
    })

    return Object.keys(groups).map(k => ({
      key: k,
      days: groups[k]
    }))
  }

  itemClass (date: Dayjs) {
    const [start, end] = this.state.select
    let className = ''
    if (start && end && date.isBefore(end) && date.isAfter(start)) className = 'in-range'
    if (start && date.isSame(start, 'd')) className = 'select'
    if (end && date.isSame(end, 'd')) className = 'select'
    if (end && date.isAfter(end, 'd')) className = 'disabled'
    if (start && date.isBefore(start, 'd')) className = 'disabled'
    if (start && date.isAfter(start.add(31, 'd'), 'd')) className = 'disabled'
    if (date.isSame(dayjs(), 'd')) className += ' current'
    return className
  }

  actionStandard (i: number, unit: dayjs.OpUnitType) {
    this.setState({
      standard: this.state.standard.add(i, unit)
    })
  }

  componentDidMount () {
    if (this.props.defaultValue) {
      const [start, end] = this.props.defaultValue
      this.setState({
        value: [
          dayjs(start),
          dayjs(end)
        ],
        standard: dayjs(start),
        select: [
          dayjs(start),
          dayjs(end)
        ]
      })
    }
  }

  hidePicker () {
    this.setState({
      picker: false
    })
  }

  changeValue (value: Dayjs[]) {
    const [start, end] = value
    const _value = [start, end.endOf('d')]
    this.setState({
      value: _value,
      select: _value
    })
    this.props.onChange && this.props.onChange(_value)
  }

  valueRangeOffset (multiple: number) {
    if (this.state.value.length === 0) return
    const [start, end] = this.state.value
    const offset = (end.diff(start, 'd') + 1) * multiple
    this.changeValue([
      start.add(offset, 'd'),
      end.add(offset, 'd')
    ])
  }

  render () {
    const weekArr = new Array(7).fill(0)
    return (
      <div className="date-select">
        <div className="box date-picker" onClick={() => {
              this.setState({
                picker: !this.state.picker
              })
            }}>
          <div className="date-icon">
            <i className="icon-font">&#xe655;</i>
          </div>
          <div className="left-icon"
            onClick={(e) => {
              e.stopPropagation()
              this.valueRangeOffset(-1)
            }}
          >
            <i className="icon-font">&#xe658;</i>
          </div>
          <div className="date-area">
            <span>{this.valueStr}</span>
          </div>
          <div className="next-icon"
            onClick={(e) => {
              e.stopPropagation()
              this.valueRangeOffset(1)
            }}
          >
            <i className="icon-font">&#xe668;</i>
          </div>

          <div className={`date-popover ${this.state.picker && 'show'}`}>
            <div className="head">
              <div className="year-prev"
                onClick={(e) => {
                  e.stopPropagation()
                  this.actionStandard(-1, 'year')
                }}
              >
                <i className="icon-font">&#xe62a;</i>
              </div>
              <div className="month-prev"
                onClick={(e) => {
                  e.stopPropagation()
                  this.actionStandard(-1, 'month')
                }}
              >
                <i className="icon-font">&#xe629;</i>
              </div>
              <div className="date-month">
                {
                  this.calendars.map((day, index) =>
                    <div key={'calendars' + index}>{day.format('YYYY.MM')}</div>
                  )
                }
              </div>
              <div className="month-next"
                onClick={(e) => {
                  e.stopPropagation()
                  this.actionStandard(1, 'month')
                }}
              >
                <i className="icon-font">&#xe62b;</i>
              </div>
              <div className="year-next"
                onClick={(e) => {
                  e.stopPropagation()
                  this.actionStandard(1, 'year')
                }}
              >
                <i className="icon-font">&#xe62c;</i>
              </div>
            </div>
            <div className="calendar-container">
              {
                this.calendarData.map((item) =>
                  <div className="calendar" key={item.key}>
                    <div className="week row">
                      {
                        weekArr.map((_, i) =>
                          <div key={'calendar-week-row' + i} className="item">{getWeek(i + 1)}</div>
                        )
                      }
                    </div>
                    {
                      item.dates.map(i =>
                        <div className="day row" key={`week_${i.key}`}>
                          {
                            i.days.map((day) =>
                              <div
                                className={`item ${this.itemClass(day)}`}
                                key={`day_${day.unix()}`}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  const { select } = this.state
                                  const isStart = select.length === 0 || select.length === 2
                                  if (this.itemClass(day) === 'disabled' && !isStart) return
                                  this.setState({
                                    select: isStart ? [day] : select.concat(day)
                                  })
                                }}
                              >{day.date()}</div>
                            )
                          }
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
            <div className="footer">
              <BaseButton className="action-btn cancel" onClick={() => this.hidePicker()}>{$i18n.$t('cancel')}</BaseButton>
              <BaseButton className="action-btn confirm"
                onClick={() => {
                  this.hidePicker()
                  this.changeValue(this.state.select)
                }}
              >{$i18n.$t('alert-confirm')}</BaseButton>
            </div>
          </div>
        </div>
        <div className={`box week-btn ${this.checkSelectStatus.week && 'active'}`}
          onClick={() => {
            this.changeValue(this.quickDate.week)
          }}
        >
          <div className="btn-text">{$i18n.$t('this-week')}</div>
        </div>
        <div className={`box week-btn ${this.checkSelectStatus.twoweek && 'active'}`}
          onClick={() => {
            this.changeValue(this.quickDate.twoweek)
          }}
        >
          <div className="btn-text">{$i18n.$t('last-two-weeks')}</div>
        </div>
        <div className={`box week-btn ${this.checkSelectStatus.month && 'active'}`}
          onClick={() => {
            this.changeValue(this.quickDate.month)
          }}
        >
          <div className="btn-text">{$i18n.$t('this-month')}</div>
        </div>

        {
          this.state.picker &&
          <div className="backgroud" onClick={() => {
            this.hidePicker()
          }}></div>
        }
      </div>
    )
  }
}

export default DateSelect
