import React from 'react'
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import Record from '@/pages/work-hours'
import ProjectDetail from '@/pages/project-analyze'
// import DataAnalyze from '@/pages/data-analyze'
import Department from '@/pages/department'
import ProjectManager from '@/pages/project-manager'
import { $i18n } from '@/lang'
import styles from './styles.module.scss'
import { observer } from 'mobx-react'
import user from '@/store/user'

const Home: React.FC = () => {
  let { path, url } = useRouteMatch()

  return (
    <div className={styles.flex_page}>
      <div className={styles.nav_tab_bar}>
        <div className={styles.nav_link}>
          <NavLink
            replace
            activeClassName={styles.nav_link_active}
            to={`${url}/record`}
          >{$i18n.$t('daily-log')}</NavLink>
          {/* <NavLink activeClassName={styles.nav_link_active} to={`${url}/data-analyze`}>{$i18n.$t('data-analyze')}</NavLink> */}
          {
            (user.userInfo?.is_vivid_manager || user.isLeader) &&
            <NavLink
              replace
              activeClassName={styles.nav_link_active}
              to={`${url}/department`}>{$i18n.$t('team-overview')}</NavLink>
          }
          <NavLink
            replace
            activeClassName={styles.nav_link_active}
            to={`${url}/project-management`}
          >{$i18n.$t('project-management')}</NavLink>
        </div>
        <div className="nav-setting">
          {
            user.userInfo?.is_vivid_manager &&
            <NavLink activeClassName="nav-active" to={`/setting`}>设置</NavLink>
          }
        </div>
      </div>
      <div className={styles.page_container}>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/record`} />
          </Route>
          <Route path={`${path}/record`}>
            <Record />
          </Route>
          <Route path={`${path}/project/:projectId`}>
            <ProjectDetail />
          </Route>
          {/* <Route path={`${path}/data-analyze`}>
            <DataAnalyze />
          </Route> */}
          <Route path={`${path}/department`}>
            <Department />
          </Route>
          <Route path={`${path}/project-management`}>
            <ProjectManager />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default observer(Home)