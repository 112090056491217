import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'querystring'
import userStore from '@/store/user'
import { observer } from 'mobx-react'
import { $i18n } from '@/lang'

const AuthPage: React.FC = () => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const result = queryString.parse(location.search.replace('?', ''))
    // changeCode(`${JSON.stringify(result)}`)
    if (typeof result.token === 'string') {
      userStore.updateToken(result.token)
      userStore.refreshInfo()
      history.replace('/home')
    }
    if (typeof result.lang === 'string') {
      $i18n.setLocale(result.lang)
    }
  }, [location.search, history])

  return (
    <div></div>
  )
}

export default observer(AuthPage)
