import { HTTP_TYPE, generateFunc } from "./request"

/** login后调用，用于换取token，获取用户信息 */
export const ApiAuth = generateFunc(HTTP_TYPE.POST, '/lark/auth/')

/** 获取用户信息 */
export const ApiGetUserInfo = generateFunc(HTTP_TYPE.GET, '/user/')

/** 解密资料 */
export const decodeUserInfo = generateFunc(HTTP_TYPE.POST, '/decrypted/')

/** 用户消息提醒设置 */
export const ApiPutSetUserMessageRemind = generateFunc(HTTP_TYPE.PUT, '/user/')

/** 创建项目 */
export const ApiCreateProject = generateFunc(HTTP_TYPE.POST, '/project/')

/** 获取项目列表 */
export const ApiGetProject = generateFunc(HTTP_TYPE.GET, '/project/')

/** 移动端获取项目列表 */
export const ApiGetProjectWithApp = generateFunc(HTTP_TYPE.GET, '/workinghours/project/')

/** 获取项目数据 */
export const ApiGetProjectInfo = generateFunc(HTTP_TYPE.GET, '/project/{:id}/')

/** 更新项目信息 */
export const ApiUpdateProjectInfo = generateFunc(HTTP_TYPE.PUT, '/project/{:id}/')

/** 工时相关 */
/** 获取工时列表 */
export const ApiGetWorkHoursList = generateFunc(HTTP_TYPE.GET, '/workinghours/')

/** 创建工时 */
export const ApiPostCreateWorkHours = generateFunc(HTTP_TYPE.POST, '/workinghours/')

/** 修改工时 */
export const ApiPutUpdateWorkHours = generateFunc(HTTP_TYPE.PUT, '/workinghours/update/')

/** 获取工时详情 */
export const ApiGetOneWorkHoursDetail = generateFunc(HTTP_TYPE.GET, '/workinghours/{:id}/')

/** 获取工时日历标记点 */
export const ApiGetWorkHoursRecord = generateFunc(HTTP_TYPE.GET, '/workinghours/record/')

/** 删除工时 */
export const ApiDeleteOneWorkHours = generateFunc(HTTP_TYPE.DELETE, '/workinghours/{:id}/')

/** 批量删除工时 */
export const ApiBatchDeleteWorkHours = generateFunc(HTTP_TYPE.DELETE, '/workinghours/delete/')

/** 修改工时状态 */
export const ApiUpdateOneWorkHoursStatus = generateFunc(HTTP_TYPE.PUT, '/workinghours/{:id}/status/')

/** 获取统计数据 */
export const ApiGetStatistics = generateFunc(HTTP_TYPE.GET, '/statistics/user/')

/** PC获取用户及下级统计数据 */
export const ApiGetPCUsersStatistics = generateFunc(HTTP_TYPE.GET, '/workinghours/team/')

/** 删除项目 */
export const ApiDeleteProject = generateFunc(HTTP_TYPE.DELETE, '/project/{:id}/')

/** 获取工时数据PC，用于绘制图表 */
export const ApiPCGetStatistics = generateFunc(HTTP_TYPE.GET, '/statistics/personal/')

/** 获取项目详情数据PC */
export const ApiPCProjectStatistics = generateFunc(HTTP_TYPE.GET, '/statistics/retrieve/')

/** wx 生成海报接口 */
export const ApiGetPoster = generateFunc(HTTP_TYPE.GET, '/user/poster/')

/** 文件导出 */
export const ApiExport = generateFunc(HTTP_TYPE.GET, '/workinghours/export/')

/** 获取成员 */
export const ApiSearchContact = generateFunc(HTTP_TYPE.GET, '/admin/search/')

/** 管理员管理列表-V2 */
export const ApiGetV2Admin = generateFunc(HTTP_TYPE.GET, '/admin/')

/** 管理员管理删除管理员-V2 */
export const ApiPostV2AdminRemove = generateFunc(HTTP_TYPE.POST, '/admin/remove/')

/** 管理员管理添加管理员-V2 */
export const ApiPostV2AdminAdd = generateFunc(HTTP_TYPE.POST, '/admin/add/')

/** 系统设置信息获取 */
export const ApiGetV2AdminSystemSetting = generateFunc(HTTP_TYPE.GET, '/system_settings/')

/** 系统设置信息更新 */
export const ApiPutV2AdminSystemSetting = generateFunc(HTTP_TYPE.PUT, '/system_settings/')

/** 系统设置信息更新 */
export const ApiGetV2AdminSystemSettingsSync = generateFunc(HTTP_TYPE.GET, '/system_settings/sync/')

/** 系统设置信息更新 */
export const ApiPutV2ProjectMember = generateFunc(HTTP_TYPE.PUT, '/project_member/{:project_id}/')