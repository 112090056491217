import React from 'react'
import './index.scss'

interface InputComponentProps {
  placeholder?: string;
  renderLeft?: () => JSX.Element;
  onChange?: (e: any) => void;
  value?: string;
  onBlur?: (e: any) => void;
  style?: React.CSSProperties;
  maxlength?: number;
}

const InputComponent: React.FC<InputComponentProps> = (props) => {
  return (
    <div className="input-component-wrapper" style={props.style} onClick={() => {}}>
      <div>
        {props.children}
      </div>
      <input
        value={props.value}
        className="input-component"
        placeholder={props.placeholder}
        onChange={(e) => props.onChange && props.onChange(e)}
        onBlur={(e) => props.onBlur && props.onBlur(e)}
        maxLength={props.maxlength || 140}
      ></input>
    </div>
  )
}

export default InputComponent
