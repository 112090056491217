import React, { useCallback, useEffect, useRef, useState } from 'react'
import style from './style.module.scss'
import closeIcon from './close.svg'
import UserStore from '@/store/user'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import config from '@/api/config'

interface TerminalProps {
  requestClose?: () => void
}

const Terminal: React.FC<TerminalProps> = (props) => {
  const history = useHistory()
  const [logs, changeLogs] = useState<{
    str: string
    date: number
    input?: string
  }[]>([])
  const bottomRef = useRef<HTMLDivElement>(null)

  const pushLog = useCallback((log: string, input?: string) => {
    changeLogs((logs) => {
      return logs.concat({
        str: log,
        date: Date.now(),
        input
      })
    })
  }, [])
  
  useEffect(() => {
    bottomRef.current?.scrollIntoView()
  }, [logs])

  const exec = useCallback((command: string) => {
    const log = (str: string) => {
      pushLog(str, command)
    }
    switch (command) {
      case 'version':
      case 'v':
        log(__VERSION__ || 'unknow')
        break
      case 'env':
        log(`node_env: ${process.env.NODE_ENV}\nreact_app_build_env: ${process.env.REACT_APP_BUILD_ENV}`)
        break
      case 'u':
      case 'user':
        log(JSON.stringify(UserStore.userInfo, undefined, 2))
        break
      case 'clear':
        changeLogs([])
        break
      case 'token':
        log(UserStore.token || '无token')
        break
      case 'api_url':
        log(JSON.stringify(config, undefined, 2))
        break
      case 'playground':
        log('跳转到playground ✨')
        history.push('/playground')
        break
      default:
        log('未知命令')
    }
  }, [pushLog, history])

  const keyEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (event.target instanceof HTMLInputElement) {
        if (event.target.value) {
          exec(event.target.value)
          event.target.value = ''
        }
      }
    }
  }, [exec])

  return (
    <div className={style.terminal}>
      <div className={style.close} onClick={props.requestClose}>
        <img src={closeIcon} alt=""/>
      </div>
      <div className={style.output}>
        {
          logs.map((log) =>
            <div className={style.log_item} key={`log_${log.date}`}>
              <p className={style.date}>{ dayjs(log.date).format('YYYY-MM-DD HH:mm:ss') }</p>
              {
                log.input && <p className={style.input}>
                  {log.input}
                </p>
              }
              <pre>
                {log.str}
              </pre>
            </div>
          )
        }
        <div ref={bottomRef}/>
      </div>
      <div className={style.input}>
        <input autoFocus type="text" onKeyUpCapture={keyEnter} placeholder="请输入"/>
      </div>
    </div>
  )
}

export default observer(Terminal)