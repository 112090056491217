import React, { useState } from 'react'
import ContactPicker from '@/components/contact-picker'

const Playground: React.FC = () => {
  const [contact, changeContact] = useState<any[]>([{
    head_img_url: "https://sf6-ttcdn-tos.pstatp.com/img/lark.avatar/241f3000f75bc809423d0~72x72.png",
    id: 126,
    name: "冯单怡"
  }])
  return (
    <div>
      Playground
      <button>选择联系人</button>
      <div>
        <ContactPicker
          value={contact}
          onChange={data => {
            changeContact(data)
          }}
        />
      </div>
    </div>
  )
}

export default Playground