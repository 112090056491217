import React from 'react'
import './index.scss'

interface BaseBubbleDialogProps {
  direction?: string;
  onMouseover?: Function
  onMouseout?: Function
}

const BaseBubbleDialog: React.SFC<BaseBubbleDialogProps> = (props) => {
  return (
    <div
      className={`base-bubble-dialog
      ${props.direction === 'bottom' && 'bottom'}
      ${props.direction === 'top' && 'top'}
      ${props.direction === 'right' && 'right'}
      ${props.direction === 'left' && 'left'}`}>
      <div className="body">
        {props.children}
      </div>
      <div className="triangle-container" style={{  }}>
        <div className="triangle"></div>
      </div>
    </div>
  )
}

BaseBubbleDialog.defaultProps = {
  direction: 'bottom'
}

export default BaseBubbleDialog
