import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './style.module.scss'
import { Modal, Input, Checkbox } from 'antd'
import { useDebounceFn, useRequest } from 'ahooks'
import { ApiSearchContact } from '@/api'
import unionBy from 'lodash/unionBy'

interface ContactPickerModalProps {
  visible: boolean
  requestClose?: () => void
  onChange?: (data: API.SearchUserInfo[]) => void
  defaultSelect?: API.SearchUserInfo[]
  /** 固定用户，不可被操作 */
  fixedUsers?: API.SearchUserInfo[]
}

async function service(d: any, query?: string) {
  let page = 1
  if (d) {
    page = (d.page + 1) || 1
  }
  const data = await ApiSearchContact({
    page: page,
    page_size: 15,
    name: query
  })
  return {
    page,
    total: data.count,
    list: data.data as API.SearchUserInfo[]
  }
}

const ContactPickerModal: React.FC<ContactPickerModalProps> = (props) => {
  const listRef = useRef<HTMLDivElement>(null)
  const [selected, changeSelected] = useState<API.SearchUserInfo[]>(() => {
    if (props.defaultSelect) return props.defaultSelect
    return []
  })
  const [query, changeQuery] = useState<string>('')

  const { data, loadMore, noMore, loading } = useRequest((d) => service(d, query), {
    loadMore: true,
    isNoMore: (d) => (d ? d.list.length >= d.total : false),
    refreshDeps: [query]
  })

  const contactList: API.SearchUserInfo[] | undefined = data?.list

  const listenScroll = useCallback((event: Event) => {
    if (event.target instanceof HTMLDivElement) {
      const bottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight
      if (bottom <= 100 && noMore === false && loading === false) {
        loadMore()
      }
    }
  }, [loadMore, loading, noMore])

  const { run: scrollListen } = useDebounceFn(listenScroll, {
    wait: 100
  })

  useEffect(() => {
    const ref = listRef.current
    ref?.addEventListener('scroll', scrollListen)
    return () => {
      ref?.removeEventListener('scroll', scrollListen)
    }
  }, [scrollListen, listRef, props.visible])

  const isSelect = useCallback((openid: string) => {
    if (selected && selected.find(i => i.openid === openid) !== undefined) {
      return true
    }
    return false
  }, [selected])
  
  const isFixed = useCallback((openid: string) => {
    if (props.fixedUsers && props.fixedUsers.find(i => i.openid === openid) !== undefined) {
      return true
    }
    return false
  }, [props.fixedUsers])

  const toggleSelect = useCallback((openid: string) => {
    if (isFixed(openid)) return
    if (isSelect(openid)) {
      changeSelected(data => {
        return data.filter(i => i.openid !== openid)
      })
    } else {
      changeSelected(data => {
        const find = contactList?.find(i => i.openid === openid)
        if (find) {
          return [find].concat(data)
        }
        return data
      })
    }
  }, [contactList, isSelect, isFixed])

  useEffect(() => {
    if (props.defaultSelect) {
      changeSelected(props.defaultSelect)
    }
  }, [props.defaultSelect])

  const selectedAndFixed = useMemo(() => {
    return unionBy((props.fixedUsers || []).concat(selected), 'openid')
  }, [props.fixedUsers, selected])

  return (
    <Modal
      title="选择联系人"
      visible={props.visible}
      onOk={() => {
        props.requestClose?.()
        props.onChange?.(selected)
      }}
      onCancel={() => props.requestClose?.()}
      cancelText="取消"
      okText="确定"
    >
      <div className={styles.contact_picker_modal}>
        <div className={styles.select_container}>
          <div className={styles.search}>
            <Input onChange={(e) => changeQuery(e.target.value)} type="text" placeholder="搜索成员"/>
          </div>
          <div className={styles.list} ref={listRef}>
            {
              contactList?.map((item) => {
                return <div key={`openid_${item.id}`} className={styles.contact_item}
                  onClick={() => {
                    toggleSelect(item.openid)
                  }}
                >
                <div className={styles.check_box}>
                  <Checkbox disabled={isFixed(item.openid)} checked={isSelect(item.openid) || isFixed(item.openid)}/>
                </div>
                <div className={styles.avatar}>
                  <img src={item.head_img_url} alt="" />
                </div>
                <div className={styles.name}>
                  {item.name}
                </div>
              </div>
              })
            }
          </div>
        </div>
        <div className={styles.selected}>
          {
            selectedAndFixed.map(item =>
              <div key={`selected_${item.id}`} className={`${styles.contact_item} ${styles.selected_item} ${isFixed(item.openid) && styles.fixed}`}
                onClick={() => {
                  toggleSelect(item.openid)
                }}
              >
              <div className={styles.avatar}>
                <img src={item.head_img_url} alt="" />
              </div>
              <div className={styles.name}>
                {item.name}
              </div>
            </div>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

export default ContactPickerModal