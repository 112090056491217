/**
 * 全部人员
 */
import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { ApiGetPCUsersStatistics } from '@/api'
import dayjs, { Dayjs } from 'dayjs'
import { generateQuickDate } from '@/utils/date'
import groupBy from 'lodash/groupBy'
import { $i18n } from '@/lang'

function generateTableData (projects: any[], range: dayjs.Dayjs[]) {
  let tableTitle = {
    name: $i18n.$t('all-staff'),
    totalHours: projects.reduce((total, item) => total + item.work_hours[1].total_hours, 0)
  }
  return {
    tableTitle
  }
}
function converResDataToTable (userDatas: any[]) {
  return userDatas.map((userItem: {
    head_img_url: string;
    id: number;
    name: string;
    work_hours: [
      {
        id: number;
        project: string;
        project_id: number;
        remark: string
        selected: string;
        status: boolean;
        work_date: number;
        working_hour: number;
      }[],
      {
        total_hours: number;
      }
    ]
  }) => {
    const groupData = groupBy(userItem.work_hours[0].map(i => ({
      ...i,
      work_date: dayjs.unix(i.work_date).startOf('d').unix()
    })), 'work_date')
    return {
      user: {
        id: userItem.id,
        name: userItem.name,
        avatar: userItem.head_img_url
      },
      hours: Object.keys(groupData).map((dateKey) => {
        const groupProjects = groupBy(groupData[dateKey], 'project_id')
        return {
          projects: Object.keys(groupProjects).map((projectKey) => {
            const item = groupProjects[projectKey][0]
            return {
              project: item.project,
              projectHours: groupProjects[projectKey].reduce((count, item) => count + item.working_hour, 0),
              projectId: item.project_id,
              value: groupData[dateKey]
            }
          }),
          hour: groupData[dateKey].reduce((count, item) => count + item.working_hour, 0),
          date: dayjs.unix(Number(dateKey)),

        }
      })
    }
  }).sort((a, b) => {
    const [aCount, bCount] = [a, b].map((item) => item.hours.reduce((count, i) => count + i.hour, 0))
    return bCount - aCount
  })
}

interface fetchUser {
  id: number
  name: string
}

class Department {
  dateRange: Dayjs[] = generateQuickDate().week
  departmentData: any = {
    tableTitle: {
      name: $i18n.$t('all-staff'),
      totalHours: 0
    }
  }
  userData: any[] = []
  hoursTableData: HourTableRow[] = []
  users: fetchUser[] = []
  fetching = false
  constructor() {
    makeAutoObservable(this, {
      dateRange: observable,
      departmentData: observable,
      hoursTableData: observable,
      userData: observable,
      users: observable,
      fetching: observable,
      getDepartmentData: action,
      changeDateRange: action
    })
  }
  async getDepartmentData(user: fetchUser, index: number = 0) {
    const findIndex = this.users.findIndex(i => i.id === user.id)

    if (findIndex !== -1) {
      if (findIndex !== 0) {
        runInAction(() => {
          this.users = this.users.slice(0, findIndex)
        })
      } else {
        runInAction(() => {
          this.users = [this.users[0]]
        })
      }
    } else {
      runInAction(() => {
        this.users = this.users.slice(0, index).concat(user)
      })
    }
    const [start, end] = this.dateRange
    this.fetching = true
    try {
      const res = await ApiGetPCUsersStatistics({
        start_date: start.unix(),
        end_date: end.endOf('day').unix(),
        user_id: user.id
      })
      if (res.retCode === 0) {
        runInAction(() => {
          this.userData = res.data.map((item: any) => {
            return {
              name: item.name,
              id: item.id,
              is_leader: item.is_leader
            }
          })
          this.departmentData = generateTableData(res.data, this.dateRange)
          this.hoursTableData = converResDataToTable(res.data)
        })
      }
    } catch (e) { }
    this.fetching = false
  }
  changeDateRange (range: dayjs.Dayjs[]) {
    this.dateRange = range
    setTimeout(() => {
      if (this.users.length > 0) {
        this.getDepartmentData(this.users[this.users.length - 1])
      }
    }, 0)
  }
}
export default new Department()
