import React from 'react'
import './index.scss'
import BaseButton from '@/components/base-button'

interface BaseDialogProps {
  visibled: boolean;
  width?: number;
  height?: number;
  title?: string;
  hasHeader?: boolean;
  customFooter?: boolean;
  onBlankClose?: Function;
  onIconClose?: Function;
  onCancel?: Function;
  onOk?: Function;
  renderFooter?: () => JSX.Element;
}

const BaseDialog: React.SFC<BaseDialogProps> = (props) => {
  const { renderFooter = () => {} } = props
  return (
    <div className={`base-dialog ${props.visibled && 'visibled'}`} onClick={() => {
      props.onBlankClose && props.onBlankClose(false)
    }}>
      <div className="body" style={{ width: `${props.width}px` }}>
        {
          props.hasHeader && (
            <div className="header">
              {props.title}
              <div className="close-icon base-hover" onClick={() => {
                props.onIconClose && props.onIconClose(false)
              }}><i className="icon-font icon">&#xe669;</i></div>
            </div>
          )
        }
        {
          props.visibled &&
          <div className="content">{props.children}</div>
        }
        {
          !props.customFooter && (
            <div className="footer">
              <BaseButton onClick={() => {
                props.onCancel && props.onCancel(false)
              }}>取消</BaseButton>
              <div className="save">
                <BaseButton customType="primary" onClick={() => {
                  props.onOk && props.onOk()
                }}>保存</BaseButton>
              </div>
            </div>
          )
        }
        {
          renderFooter()
        }
      </div>
    </div>
  )
}

BaseDialog.defaultProps = {
  width: 686,
  height: 492,
  title: '提示',
  hasHeader: true,
  customFooter: false
}

export default BaseDialog
