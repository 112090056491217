import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import './index.scss'
import InputComponent from '@/components/input'
import Popover from '@/components/popover'
import { catchEvent } from '@/utils/syntax'
import Pagination from '@/components/pagination'
import { ApiDeleteProject, ApiUpdateProjectInfo, ApiCreateProject, ApiPutV2ProjectMember } from '@/api'
import dayjs from 'dayjs'
import { generateQuickDate } from '@/utils/date'
import Dialog from '@/components/base-dialog'
import BaseButton from '@/components/base-button'
import { observer } from 'mobx-react'
import EmptyProject from '@/assets/images/empty-project.png'
import { $i18n } from '@/lang'
import ProjectStore from '@/store/project'
import UserStore from '@/store/user'
import { Modal, message } from 'antd'
import { zh_CN, en_US } from '@/lang/config'
import SelectorPicker from '@/components/selector-picker'
import ContactPicker from '@/components/contact-picker'

@observer
class ProjectManager extends React.Component<RouteComponentProps> {
  defaultForm = {
    name: '',
    start: 0,
    end: 0,
    members: [] as API.SearchUserInfo[],
    preHours: undefined,
  }

  i18nTitle = $i18n.$t('app-name')

  projectSelectRange = [{
    value: 0,
    label: $i18n.$t('project-not-archive')
  }, {
    value: 1,
    label: $i18n.$t('project-archived')
  }, {
    value: 2,
    label: $i18n.$t('project-all')
  }]

  state = {
    showPopover: false,
    popoverPosition: {
      x: 0,
      y: 0
    },
    actionProject: null as Project | null,
    projectDialog: {
      edit: false,
      visible: false,
      form: Object.assign({}, this.defaultForm),
      fixedUsers: [] as API.SearchUserInfo[]
    },
    memberDialog: {
      visible: false,
      form: Object.assign({}, this.defaultForm),
      fixedUsers: [] as API.SearchUserInfo[]
    },
    postData: false
  }
  name: any
  id: any
  status: number | undefined
  start_time: any
  end_time: any
  project_member: any
  creator: any
  pre_working_hours: any

  get currentTableTitle () {
    const titleMap = {
      '0': $i18n.$t('project-not-archive'),
      '1': $i18n.$t('project-archived'),
      '2': $i18n.$t('project-all')
    }
    const value = ProjectStore.params.status as any
    if (value === null) {
      return titleMap[2]
    }
    return titleMap[value as '0' | '1' | '2']
  }

  componentDidMount () {
    // super.componentDidMount()
    document.title = this.i18nTitle
    this.fetchList()
  }

  deleteProject () {
    if (!this.state.actionProject) return
    Modal.confirm({
      title: $i18n.$t('alert'),
      content: `${$i18n.$t('confirm-delete')} "${this.state.actionProject.name}" ？${$i18n.$t('forever-del')}`,
      cancelText: $i18n.$t('cancel'),
      okText: $i18n.$t('alert-confirm'),
      onOk: async () => {
        if (!this.state.actionProject) return
        const res = await ApiDeleteProject({
          id: this.state.actionProject.id
        })
        if (res.retCode === 0) {
          message.success($i18n.$t('delete-success'))
        }
        this.setState({
          showPopover: false
        })
        this.fetchList()
      }
    })
  }

  async initFetch () {
    ProjectStore.params.current = 1
    this.fetchList()
  }

  async fetchList () {
    ProjectStore.fetchData()
  }

  checkInput () {
    const { form } = this.state.projectDialog

    if (!form.name.trim()) {
      message.warning($i18n.$t('alert-project-name'))
      return false
    }
    if (form.end && form.start > form.end) {
      message.warning($i18n.$t('alert-error-time'))
      return false
    }
    return true
  }

  async postProject () {
    if (!this.checkInput()) return
    message.loading({
      content: $i18n.$t('data-posting'),
      key: 'data-posting',
      duration: 0
    })
    this.setState({
      postData: true
    })

    const { form } = this.state.projectDialog
    const query: any = {
      start_time: form.start,
      end_time: form.end === 0 ? 0 : dayjs(form.end * 1000).endOf('d').unix(),
      name: form.name,
      // remark: form.remark,
      pre_working_hours: form.preHours,
      project_member: JSON.stringify(form.members.map(item => {
        return {
          id: item.id,
          name: item.name,
          head_img_url: item.head_img_url,
          openid: item.openid
        }
      }))
      // color: form.color
    }

    if (this.state.projectDialog.edit) {
      query.id = this.state.actionProject ? this.state.actionProject.id : 0
    }
    const api = this.state.projectDialog.edit ? ApiUpdateProjectInfo : ApiCreateProject
    try {
      const res = await api(query)

      if (res.retCode === 0) {
        // @ts-ignore
        message.destroy('data-posting')
        message.success(`${this.state.projectDialog.edit ? $i18n.$t('update-success') : $i18n.$t('create-success')}`)
        this.setState({
          projectDialog: {
            ...this.state.projectDialog,
            visible: false
          },
          showPopover: false
        })

        this.fetchList()
      }
    } catch (e) { }
    this.setState({
      postData: false
    })
  }

  resetDialogForm () {
    return new Promise((resolve) => {
      this.setState({
        projectDialog: {
          ...this.state.projectDialog,
          form: Object.assign({}, this.defaultForm)
        }
      }, () => {
        resolve('succeed')
      })
    })
  }

  updateDialogForm (key: string, value: any) {
    this.setState({
      projectDialog: {
        ...this.state.projectDialog,
        form: {
          ...this.state.projectDialog.form,
          [key]: value
        }
      }
    })
  }

  async archivedProject () {
    const action = async () => {
      message.loading({
        content: $i18n.$t('loading'),
        key: 'archived',
        duration: 0
      })
      if (!this.state.actionProject) return
      const res = await ApiUpdateProjectInfo({
        id: this.state.actionProject.id,
        status: this.state.actionProject.status === 1 ? 0 : 1
      })
      message.destroy('archived')

      if (res.retCode === 0) {
        this.fetchList()
      }
    }
    if (!this.state.actionProject) return
    if (this.state.actionProject.status === 0) {
      Modal.confirm({
        title: $i18n.$t('archive-title'),
        content: $i18n.$t('archive-content'),
        cancelText: $i18n.$t('cancel'),
        okText: $i18n.$t('alert-confirm'),
        onOk: async () => {
          action()
        }
      })
    } else {
      action()
    }
    this.setState({
      showPopover: false
    })
  }

  openEdit () {
    if (!this.state.actionProject) return
    this.setState({
      projectDialog: {
        ...this.state.projectDialog,
        form: {
          name: this.state.actionProject.name,
          start: this.state.actionProject.start_time,
          end: this.state.actionProject.end_time,
          members: this.state.actionProject.project_member.map((item) => ({
            name: item.name,
            openid: item.open_id,
            head_img_url: item.head_img_url,
            id: item.id
          })),
          preHours: this.state.actionProject.pre_working_hours
          // color: this.state.actionProject.color
        },
        visible: true,
        edit: true,
        fixedUsers: [{
          head_img_url: this.state.actionProject.creator_head_img_url,
          id: this.state.actionProject.creator,
          name: this.state.actionProject.creator_name,
          openid: this.state.actionProject.creator_openid
        }]
      }
    })
  }

  putV2ProjectMember () {
    const params = JSON.stringify(this.state.memberDialog.form.members)
    ApiPutV2ProjectMember({
      project_id: this.state.actionProject?.id,
      project_member: params
    }).then(res => {
      if (res.retCode === 0) {
        message.success('成员更新成功')
        this.setState({
          memberDialog: { ...this.state.memberDialog, visible: false },
          showPopover: false
        }, () => {
          this.fetchList()
        })
      }
    })
  }

  openEditMember () {
    if (!this.state.actionProject) return
    this.setState({
      memberDialog: {
        ...this.state.memberDialog,
        visible: true,
        form: {
          name: this.state.actionProject.name,
          start: this.state.actionProject.start_time,
          end: this.state.actionProject.end_time,
          members: this.state.actionProject.project_member.map((item) => ({
            name: item.name,
            openid: item.open_id,
            head_img_url: item.head_img_url,
            id: item.id
          })),
          preHours: this.state.actionProject.pre_working_hours
          // color: this.state.actionProject.color
        },
        fixedUsers: this.state.actionProject.is_project_owner ? [{
          head_img_url: this.state.actionProject.creator_head_img_url,
          id: this.state.actionProject.creator,
          name: this.state.actionProject.creator_name,
          openid: this.state.actionProject.creator_openid
        }] : this.state.actionProject.project_member.map((item) => {
          return {
            head_img_url: item.head_img_url,
            id: item.id,
            name: item.name,
            openid: item.open_id
          }
        })
      }
    })
  }

  getUnit = () => {
    switch ($i18n.getLocale()) {
      case zh_CN:
        return '小时'
      case en_US:
        return 'hour'
    }
  }

  render () {
    return (
      <div className="project-container base-page">
        <div className="head">
          <div className="filter-form">
            <div className="select-picker-wrap">
              <SelectorPicker
                placeholder={$i18n.$t('project-status')}
                options={this.projectSelectRange}
                labelKey='label'
                valueKey='value'
                rcKey='value'
                defaultValue={ProjectStore.params.status === null ? 2 : ProjectStore.params.status}
                allowClear
                onChange={(_value) => {
                  const value = Number(_value)
                  if (value === 2) {
                    ProjectStore.params.status = null
                  } else {
                    ProjectStore.params.status = value
                  }
                  this.initFetch()
                }}
                onClear={() => {
                  ProjectStore.params.status = null
                  this.initFetch()
                }}
              ></SelectorPicker>
            </div>
            <div className="input-name">
              <InputComponent
                placeholder={$i18n.$t('project-name')}
                value={ProjectStore.params.name}
                onChange={(e) => {
                  const value = e.target.value
                  ProjectStore.params.name = value
                  if (value === '') {
                    this.initFetch()
                  }
                }}
                onBlur={() => {
                  if (ProjectStore.params.name) {
                    this.initFetch()
                  }
                }}
                style={{
                  width: '310px'
                }}
              >
                <i className="icon-font search-icon">&#xe661;</i>
              </InputComponent>
            </div>
          </div>

          <div
            className="add-button base-hover"
            onClick={async () => {
              await this.resetDialogForm()
              this.setState({
                projectDialog: {
                  ...this.state.projectDialog,
                  visible: true,
                  edit: false,
                  fixedUsers: UserStore.userInfo ? [{
                    head_img_url: UserStore.userInfo.head_img_url,
                    id: UserStore.userInfo.id,
                    name: UserStore.userInfo.name,
                    openid: UserStore.userInfo.openid
                  }] : []
                }
              })
            }}
          >
            <i className="icon-font add-icon">&#xe63a;</i>
            {$i18n.$t('add-project')}
          </div>
        </div>

        <div className="list-container">
          {
            ProjectStore.list.length > 0 ?
              <div className="container">
                <div className="title-info">
                  <div className="info-container">
                    <div className="left">
                      <div className="all-member">
                        <i className="icon-font">&#xe662;</i>
                        <span className="all-text">{this.currentTableTitle}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-header row">
                  <div className="item">{$i18n.$t('project-name')}</div>
                  {/* <div className="item date-range">{$i18n.$t('start-and-end-time')}</div> */}
                  <div className="item">{$i18n.$t('project-member')}</div>
                  <div className="item">{$i18n.$t('project-creation-time')}</div>
                  {/* <div className="item">{$i18n.$t('cumulative-time-budget-time')}</div> */}
                  <div className="item">{$i18n.$t('project-creator')}</div>
                  <div className="item">{$i18n.$t('operation')}</div>
                </div>
                <div className="table-body">
                  {
                    ProjectStore.list.map((data) => (
                      <div className={`row ${data.status === 1 && 'archived'}`} key={`project-${data.id}`}>
                        {
                          data.status === 1 && <div className="archived-tag">{$i18n.$t('archived')}</div>
                        }
                        {
                          data.can_visible_detail ?
                            <div
                              className="item name-text"
                              onClick={() => {
                                const [start, end] = generateQuickDate().week
                                this.props.history.push(`/home/project/${data.id}?start=${start.unix()}&end=${end.endOf('d').unix()}`)
                              }}
                            >{data.name}</div> :
                            <div className="item">{data.name}</div>
                        }
                        {/* <div className="item date-range">{formatRange(data.start_time, data.end_time || 0)}</div> */}
                        <div className="item">{data.project_member_count || 0}</div>
                        <div className="item">{dayjs(data.created_at * 1000).format('YYYY.MM.DD')}</div>
                        {/* <div className={`item ${data.pre_working_hours && (data.project_all_hours > data.pre_working_hours) && 'overtime'}`}>{data.project_all_hours}h/{data.pre_working_hours ? `${data.pre_working_hours}h` : '-'}</div> */}
                        <div className="item">{data.creator_name}</div>
                        <div className="item action">
                          {
                            /** 如果项目以及归档，只有管理员可以操作 */
                            ((data.status === 1 && data.is_project_owner) || data.status !== 1) &&
                            // userInfo && userInfo.id === data.creator &&
                            <div onClick={((e: any) => {
                              this.setState({
                                showPopover: true,
                                popoverPosition: {
                                  x: e.pageX,
                                  y: e.pageY
                                },
                                actionProject: data
                              })
                            })}>
                              <i className="icon-font">&#xe675;</i>
                            </div>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>

                <div className="pagination">
                  <Pagination
                    size={ProjectStore.params.size}
                    current={ProjectStore.params.current}
                    total={ProjectStore.params.total}
                    sizes={[10, 15, 30]}
                    onSizeChange={size => {
                      ProjectStore.params.size = size
                      this.initFetch()
                    }}
                    onChange={(page) => {
                      ProjectStore.params.current = page
                      this.fetchList()
                    }}
                  ></Pagination>
                </div>
              </div> :
              <div className="empty">
                <img
                  src={EmptyProject}
                  className="empty-image"
                  alt=""
                ></img>
                <div className="empty-tips">{$i18n.$t('no-project')}</div>
              </div>
          }
        </div>

        <Popover
          visible={this.state.showPopover}
          onChange={({ visible }) => {
            this.setState({
              showPopover: visible
            })
          }}
          position={this.state.popoverPosition}
        >
          <div onClick={catchEvent} className="action-popover">
            {
              this.state.actionProject?.is_project_owner && this.state.actionProject?.status !== 1 &&
              <div
                className="item primary"
                onClick={() => this.openEdit()}
              >{$i18n.$t('pc-bubble-edit')}</div>
            }

            {
              this.state.actionProject?.status !== 1 &&
              <div
                className="item"
                onClick={() => {
                  this.openEditMember()
                }}
              >编辑成员</div>
            }

            {
              this.state.actionProject?.is_project_owner &&
              <div
                className="item"
                onClick={() => this.archivedProject()}
              >{this.state.actionProject && this.state.actionProject.status === 1 ? $i18n.$t('cancel-archive') : $i18n.$t('archive')}
              </div>
            }
            {
              this.state.actionProject?.is_project_owner && this.state.actionProject?.status !== 1 &&
              <div
                className="item delete"
                onClick={() => {
                  this.deleteProject()
                }}
              >{$i18n.$t('delete')}</div>
            }
          </div>
        </Popover>

        <Dialog
          visibled={this.state.projectDialog.visible}
          customFooter
          width={400}
          height={310}
          title={this.state.projectDialog.edit ? $i18n.$t('edit-project') : $i18n.$t('create-project')}
          onIconClose={() => {
            this.resetDialogForm()
            this.setState({
              projectDialog: {
                ...this.state.projectDialog,
                visible: false
              },
              showPopover: false
            })
          }}
          renderFooter={() =>
            <div className="add-footer">
              {/* <BaseButton style={{marginRight: '20px'}} onClick={() => this.resetDialogForm()}>{$i18n.$t('reset')}</BaseButton> */}
              <BaseButton loading={this.state.postData} customType="primary" onClick={() => this.postProject()}>{$i18n.$t('work-hours-save')}</BaseButton>
            </div>
          }
        >
          <div className="project-form">
            <div className="line">
              <div className="icon">
                <i className="icon-font">&#xe659;</i>
              </div>
              <div className="required">*</div>
              <div className="control">
                <InputComponent
                  placeholder={$i18n.$t('input-project-name')}
                  value={this.state.projectDialog.form.name}
                  onChange={(e) => {
                    this.updateDialogForm('name', e.target.value)
                  }}
                  maxlength={100}
                ></InputComponent>
              </div>
            </div>
            <div className="line">
              <div className="icon">
                <i className="icon-font">&#xe66a;</i>
              </div>
              <div className="required"></div>
              <div className="control">
                <InputComponent
                  placeholder={$i18n.$t('input-project-pre-hours')}
                  value={this.state.projectDialog.form.preHours}
                  onChange={(e) => {
                    const number = Number((e.target.value.match(/\d+/g) || []).join(''))
                    this.updateDialogForm('preHours', number || '')
                  }}
                ></InputComponent>
              </div>
              <div className="suffix">{this.getUnit()}</div>
            </div>
            {
              !this.state.projectDialog.edit &&
              <div className="line">
                <div className="icon">
                  <i className="icon-font">&#xe66b;</i>
                </div>
                <div className="required"></div>
                <div className="control">
                  <div className="label">{$i18n.$t('add-project-members')}</div>
                  <ContactPicker
                    onChange={(value: any) => {
                      this.updateDialogForm('members', value)
                    }}
                    value={this.state.projectDialog.form.members}
                    fixedUsers={this.state.projectDialog.fixedUsers}
                    class-name="contact-picker"
                  ></ContactPicker>
                </div>
              </div>
            }
          </div>
        </Dialog>


        <Dialog
          visibled={this.state.memberDialog.visible}
          customFooter
          width={400}
          height={310}
          title='编辑成员'
          onIconClose={() => {
            this.setState({
              memberDialog: {
                ...this.state.memberDialog,
                visible: false
              },
              showPopover: false
            })
          }}
          renderFooter={() =>
            <div className="add-footer">
              <BaseButton
                loading={this.state.postData}
                customType="primary"
                onClick={() => this.putV2ProjectMember()}
              >{$i18n.$t('work-hours-save')}</BaseButton>
            </div>
          }
        >

          <div className="line">
            {/* <div className="icon">
              <i className="icon-font">&#xe66b;</i>
            </div> */}
            <div className="required"></div>
            <div className="control">
              {/* <div className="label">{$i18n.$t('add-project-members')}</div> */}
              <ContactPicker
                onChange={(value: any) => {
                  this.setState({
                    memberDialog: {
                      ...this.state.memberDialog,
                      form: {
                        ...this.state.memberDialog.form,
                        members: value
                      }
                    }
                  })
                }}
                value={this.state.memberDialog.form.members}
                fixedUsers={this.state.memberDialog.fixedUsers}
                class-name="contact-picker"
              ></ContactPicker>
            </div>
          </div>

        </Dialog>



      </div >
    )
  }
}

export default withRouter(ProjectManager)
