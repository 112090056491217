const en_US_package: Lang = {
  'hour': 'hours',
  'works': 'Time',
  'project': 'Projects',
  'data': 'Reports',
  'set': 'Settings',
  'create-project': 'Add a Project',
  'edit-project': 'Edit Project',
  'work-hours': 'Time',
  // 晒成就
  'show-achievement': 'Achievement',
  'create-work-hours': 'Create time entry',
  'blank-text': 'No time',
  'work-hours-spend-time': 'Total',
  'work-hours-each-represents': 'Each block 0.5 hour',
  'work-hours-projects': 'Projects',
  'work-hours-optional-project': 'List',
  'work-hours-projects-list': 'Add Project',
  'work-hours-cancel': 'Cancel',
  'work-hours-project-selected': ' selected ',
  'work-hours-delete': 'Delete',
  'work-hours-add-details': 'Add note',
  'work-hours-detail': 'Please add note',
  'work-hours-tips': 'Tips',
  'work-hours-overwritten': 'After reselection, the content on the time block will be overwritten!',
  'work-hours-continue': 'Continue',
  'work-hours-sure-delete': 'Are you sure you want to delete ?',
  'require-time': 'Duration',
  'pc-confirm': 'Confirm',
  'pc-bubble-edit': 'Edit',
  'pc-bubble-add': 'Add',
  'work-hours-add-title': 'Add time entry',
  'work-hours-project-name': 'Select a project',
  'work-hours-number': 'Select duration',
  'work-hours-unit': 'h',
  'work-hours-save': 'Save',
  'work-hours-message-remind': 'Message Reminder',
  'work-hours-working-remind': 'Reminder to work',
  'work-hours-forgotten-remind': 'Review reminder',
  'work-hours-forgotten-remind-time': 'Reminder time',
  'work-hours-working-remind-default': 'AM 5h',
  'work-hours-worked-remind-default': 'PM 6h',
  'work-hours-forgotten-remind-time-default': 'PM 5h',
  // 移动端工时
  'no-work-add-project': 'There is no project. Please add a project first.',
  'can-not-blank': 'Time entry note must be filled',
  'work-hours-cell-desc-total': 'Total / h',
  'mobil-hours-details': 'Note',
  'can-not-over-24': 'Time addition shall not be greater than 24 hours',
  'should-select-time': 'Please choose the time first',
  'loading': 'Loading...',
  'now': 'Now',
  'input-project-name': 'Less than 100 characters',
  'input-project-pre-hours': 'Budget time',
  'add-project': 'Add project',
  'start-time': 'Start time',
  'end-time': 'End time',
  'data-posting': 'Submitting',
  'archived': 'Archived',
  'project-total': 'Project total/Each',
  'hour-total': 'Tacking hours/hours',
  'member-total': 'Number of hours tracked/people',
  'project-list': 'Project list',
  'project-name': 'Project name',
  'empty-project': 'No Project',
  'empty-data': 'No data',
  'placeholder-input': 'Enter',
  'placeholder-select': 'Select',
  'add-participants': 'Add members',
  'mark-color': 'Select Project color',
  'project-color': 'Project color',
  'cancel': 'Cancel',
  'save': 'Save',
  'save-success': 'Done',
  // 数据
  'total-time-this-week': 'Total time this week',
  'total-work-hours': 'Total/h',
  'time-h': 'Time/h',
  'time-condition-of-everyday': 'Weekly Data',
  'time-detail-of-everyday': 'Daily logs',
  'add-hours-detail': 'Add note',
  'edit-hours-detail': 'Edit note',
  'watch-more-data-in-pc': 'For more data analysis, please check the desktop client',
  'project-work-hour': 'Total time',
  'project-member': 'Members',
  'archive-title': 'The project will be archived, and the project time cannot be tracked after saving',
  'archive-content': 'We will let others involved in the project know the changes',
  'guide-next': 'Next step',
  'guide-finish': 'Got it',
  'network-retry': 'Back to retry',
  'network-error': 'Network Error',
  'network-error-label': 'The network left home',
  'empty-task-day': 'No Tracking Record. ',
  // PC
  'color-tips-less': 'less',
  'color-tips-more': 'more',
  'i-join-project': 'Projects',
  'i-record-hours': 'Tracked hours',
  'i-data-distribution': 'My Tracked data',
  'i-joined-project': 'My projects',
  'pc-no-project-desc': 'No project, go to create a project',
  'pro-tips': 'The current version does not support different data permission, the Pro version is coming soon.',
  // 数据分析
  'data-analyze': 'Reports',
  'this-week': 'Current week',
  'last-two-weeks': 'Current two weeks',
  'this-month': 'Current month',
  'cumulative-time': 'Total time',
  'budget-time': 'Budget time',
  'time-of-day': 'Tracked hours during this period',
  'total': 'Total',
  'total-time': 'Tracked hours',
  'project-creator': 'Creator',
  'project-creation-time': 'Created time',
  'start-and-end-time': 'Start and end time',
  'archive': 'Archive',
  'cancel-archive': 'Cancel archive',
  'no-data': 'No Data',
  'other-projects': 'Other Projects',
  // 人员概况
  'team-overview': 'Teams',
  'all-staff': 'All team members',
  'total-time-during-this-period': 'Tracked hours during this period',
  // 项目管理
  'project-management': 'Projects',
  'project-status': 'Project status',
  'project-all': 'All projects',
  'project-archived': 'Archived',
  'project-not-archive': 'Active',
  'add-project-members': 'Search users',
  'cumulative-time-budget-time': 'Total / Budget time',
  'operation': 'Actions',
  'no-project': 'No Project',
  'list-total': 'Total',
  'list-page': 'Page',
  'list-item': 'Items',
  'list-go': 'Go to',
  'reset': 'Reset',
  'delete': 'Delete',
  'update-success': 'Update Success',
  'create-success': 'Create Success',
  'alert': 'Alert',
  'confirm-delete': 'Confirm delete',
  'forever-del': `This Project and all it's records will be permanently deleted.`,
  'alert-confirm': 'Confirm',
  'delete-success': 'Delete Success',
  'alert-project-name': 'Please enter a project name',
  'alert-error-time': 'The start time cannot be greater than the end time',
  'alert-project-color': 'Please mark item color',
  'alert-error-page': 'Please enter the correct page number',
  //工时记录
  'daily-log': 'My logs',
  'details': 'Details',
  'alert-select-time': 'Please select hours',
  'have-accumulated-records': 'You have accumulated records',
  'records-days': 'day!',
  'show-your-achieve': 'Go and bask in your new achievement!',
  'show-off': 'Show off',
  // 设置
  'help-and-feedback': 'Feedback',
  'modal-title': 'Welcome to use VividLog',
  'body-using': 'if you have any questions, welcome positive feedback, feedback email',
  'body-do-better': 'With your participation, we will do better.',
  'i-known': 'Got it',
  'app-name': 'VividLog',
  'close-remind': 'Close Remind',
  'save-image': 'Save image',
  // App更新
  'app-update-title': 'App Updates',
  'app-update-content': 'App update is ready, press yes to apply the update now',
  // 请求
  'request-timeout': 'Requst timeout',
  'request-fail': 'Network error',
  'export': 'Export',
  'exporting': 'Exporting data',
  // 微信独有
  'share-slogan': 'Lightweight time tracking',
  'wx-works': 'Logs',
  'wx-data': 'Reports',
  'wx-work-hours': 'Logs',
  'wx-time-h': 'Logs/h',
  'wx-project': 'Labels',
  'wx-project-list': 'Label list',
  'wx-edit-hours-detail': 'Edit note',
  'wx-work-hours-projects-list': 'Add Label',
  'wx-no-work-add-project': 'There is no label. Please add a label first.',
  'wx-create-project': 'Add a Label',
  'wx-edit-project': 'Edit label',
  'wx-budget-time': 'Budget time',
  'wx-empty-project': 'No Label',
  'wx-cumulative-time': 'Total time',
  'wx-total-time-this-week': 'Total time this week',
  'wx-time-detail-of-everyday': 'Daily logs',
  'wx-add-hours-detail': 'Add note',
  'wx-empty-task-day': 'No Tracking Record. ',
  'wx-work-hours-add-title': 'Add time entry',
  'wx-project-name': 'Label name',
  'wx-mobil-hours-details': 'Note',
  'wx-confirm-delete': 'Confirm delete',
  'wx-forever-del': `This Label and all it's records will be permanently deleted.`,
  'wx-mine': 'Mine',
  'wx-no-name': 'No name',
  'wx-sun-achievement': 'Sun achieve',
  'wx-cumulative-record-rate': 'Consecutive days',
  'wx-cumulative-record-days': 'Days',
  'wx-cumulative-record-time': 'Hours',
  'wx-cumulative-record-total': 'Time entries',
  'wx-remind-set': 'Remind set',
  'wx-record-reminder-time': 'Record reminder time',
  'wx-forgotten-reminder-time': 'Forgotten reminder time',
  'wx-recommend-to-friends': 'Recommend vividlog to friends'
}

export default en_US_package