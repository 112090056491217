import { TIPS_LIST } from "./constants"

/**
 * 生成工时对应背景色
 * @param hours 工时
 */
export function generateColor (hours: number) {
  if (!hours) return ''
    else if (hours > 0 && hours <= 2) return TIPS_LIST[0].color
    else if (hours <= 4)  return TIPS_LIST[1].color
    else if (hours <= 6)  return TIPS_LIST[2].color
    else if (hours <= 8)  return TIPS_LIST[3].color
    else if (hours <= 12)  return TIPS_LIST[4].color
    else if (hours <= 16)  return TIPS_LIST[5].color
    else return TIPS_LIST[6].color
}