import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import Auth from '@/pages/auth'
// import Setting from '@/pages/setting';
import EnvErrorPage from './pages/error/env'
import Home from './pages/home'
import userStore from '@/store/user'
import { observer } from 'mobx-react'
import Terminal from '@/components/terminal'
import Playground from './pages/playground'

const Setting = React.lazy(() => import(
  /* webpackChunkName: "setting" */
  './pages/setting'
))

const TerminalWrapper: React.FC = () => {
  const [visible, changeVisible] = useState(false)
  const time = useRef(0)
  const tapCount = useRef(0)

  const tapCommand = useCallback((event: KeyboardEvent) => {
    if (event.code !== 'Slash' || event.ctrlKey === false) return
    const now = new Date().getTime()
    if (now - time.current < 1000) {
      tapCount.current += 1
    } else {
      tapCount.current = 0
    }
    if (tapCount.current >= 4) {
      changeVisible(true)
    }
    time.current = now
  }, [])

  useEffect(() => {
    document.body.addEventListener('keyup', tapCommand)
    return () => {
      document.body.removeEventListener('keyup', tapCommand)
    }
  }, [tapCommand])

  if (visible) {
    return <Terminal requestClose={() => changeVisible(false)}/>
  }

  return null
}

const App: React.FC = () => {
  const token = userStore.token

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/setting">
          <Suspense fallback={<div>跳转中</div>}>
            <Setting />
          </Suspense>
        </Route>
        <Route path="/playground">
          <Playground/>
        </Route>
        <Route path="/auth">
          <Auth />
        </Route>
        <Route path="/error/env">
          <EnvErrorPage />
        </Route>
        <Route path="/home">
          {
            !token &&
            <Redirect to="/error/env" />
          }
          <Home />
        </Route>
      </Switch>
      <TerminalWrapper/>
    </Router>
  )
}

export default observer(App)