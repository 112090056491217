import { $i18n } from '@/lang'
import { message } from 'antd'
import axios from 'axios'
import config from './config'

export interface BaseResponse {
  retCode: number,
  data?: any
}

export const generateFunc = (type: HTTP_TYPE, _url: string, baseUrl?: string) => (params?: any) => {
  let url = _url
  // 替换url中的{:id}等模板参数
  if (/{:\w+}/.test(url)) {
    (url.match(/{:\w+}/g) || []).forEach(matched => {
      const key = String(matched.match(/\w+/))
      url = url.replace(/{:\w+}/, params[key])
      delete params[key]
    })
  }
  return request(type, url, params, undefined, baseUrl)
}


export enum HTTP_TYPE {
  GET, POST, PUT, DELETE, UPLOAD_POST, UPLOAD_PUT
}

function TYPE_TO_STRING (type: HTTP_TYPE) {
  switch (type) {
    case HTTP_TYPE.GET: return 'GET'
    case HTTP_TYPE.POST: return 'POST'
    case HTTP_TYPE.PUT: return 'PUT'
    case HTTP_TYPE.DELETE: return 'DELETE'
    case HTTP_TYPE.UPLOAD_POST: return 'POST'
    case HTTP_TYPE.UPLOAD_PUT: return 'PUT'
  }
}

// function getCurrentPage (): string {
//   const pages = Taro.getCurrentPages()
//   const url = pages[pages.length - 1].__route__
//   return url
// }

function failPage () {
  // TODO: 超时页面
  // if (getCurrentPage() === 'pages/timeout/index') {
  return true
  // }
}

export function getToastIcon (icon: 'success' | 'loading' | 'none' | 'info' | 'error' | 'warning') {
  switch (icon) {
    case 'success':
    case 'loading':
      return icon
    case 'none':
      return 'info'
    case 'info':
    case 'error':
    case 'warning':
      return icon
  }

  return icon
}


// 网络请求的总方法
// @ts-ignore
export function request(type: HTTP_TYPE, url: string, params: any, options?: any, baseUrl = config.ROOT_API_URL) {
  const body = {} as any
  for (let key in params) {
    const value = params[key]
    if (value !== null && value !== undefined) {
      body[key] = value
    }
  }

  const isUpload = (type === HTTP_TYPE.UPLOAD_POST || type === HTTP_TYPE.UPLOAD_PUT)
  const isGet = (type === HTTP_TYPE.GET)
  return axios({
    url: baseUrl + url,
    method: TYPE_TO_STRING(type),
    data: body,
    timeout: 5000,
    params: isGet ? Object.assign({}, body) : undefined,
    headers: {
      'Authorization': localStorage.getItem('token'),
      'Content-Type': isUpload ? 'multipart/form-data' : 'application/json'
    }
  }).then(response => {
    message.destroy('data-posting')
    if (response.status !== 200) {
      return Promise.reject()
    }
    if (response.data.retCode && response.data.retCode !== 0 && Number(response.data.retCode) !== 40000 && response.data.retMsg !== '没有更多数据了') {
      // Taro.showToast({
      //   title: response.data.err || response.data.retMsg,
      //   // @ts-ignore
      //   icon: getToastIcon('warning')
      // })
      message.warn(response.data.err || response.data.retMsg)
    } else if (Number(response.data.retCode) === 40000) {
      message.warn(response.data.err || response.data.retMsg)
      // Taro.showToast({
      //   title: response.data.err || response.data.retMsg,
      //   icon: 'none'
      // })
    }
    return response.data
  }).catch((err: any) => {
    // console.log('error', Object.keys(err))
    // Taro.hideLoading()
    message.destroy()
    message.destroy()
    if (err.status >= 500) {
      message.warn($i18n.$t('request-fail'))
      // Taro.showToast({
      //   title: Taro.$i18n.$t('request-fail'),
      //   // @ts-ignore
      //   icon: getToastIcon('error')
      // })
    } else if (err.status === 403) {
      if (Number(err.response.data.retCode) === 40011) {
        setTimeout(() => {
          if (failPage()) {
            return
          } else {
            // Taro.navigateTo({
            //   url: `/pages/timeout/index`
            // })
          }
        }, 500)
      } else {
        // Taro.showToast({
        //   title: Taro.$i18n.$t('request-timeout'),
        //   // @ts-ignore
        //   icon: getToastIcon('warning')
        // })
        message.warn('当前身份过期，请重新打开小程序')
        setTimeout(() => {
          if (failPage()) {
            return
          } else {
            // Taro.navigateTo({
            //   url: `/pages/timeout/index`
            // })
          }
        }, 500)
      }
    } else if (err.status === 1) {
      // Taro.showToast({
      //   title: Taro.$i18n.$t('request-timeout'),
      //   // @ts-ignore
      //   icon: getToastIcon('warning')
      // })
      message.warn($i18n.$t('request-timeout'))
      setTimeout(() => {
        if (failPage()) {
          return
        } else {
          // Taro.navigateTo({
          //   url: `/pages/timeout/index`
          // })
        }
      }, 500)
    } else {
      let error = '出错了'
      if (err.response && err.response.data) error = err.response.data.err || err.response.data.retMsg
      message.error(error || '服务器错误')
      // Taro.showToast({
      //   title: error,
      //   // @ts-ignore
      //   icon: getToastIcon('error')
      // })
    }

    return Promise.reject(err)
  })
}