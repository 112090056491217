import zh from './zh-CN'
import en from './en-US'

export const getBrowserLanguage = () => {
  let language = localStorage.getItem('language') || ''
  // const navigatorLanguage = navigator.language
  // language = language || navigatorLanguage
  language = language.replace(/-/, '_').toLowerCase()
  if (language === 'zh_cn' || language === 'zh') {
    language = 'zh_CN'
  } else {
    language = 'en_US'
  }
  return language
}

class I18n {
  lang: string = 'zh_CN'
  constructor() {
    this.lang = getBrowserLanguage()
  }
  $t(key: string) {
    return (this.lang === 'zh_CN' ? zh : en)[key] || `i.${key}`
  }
  getLocale () {
    return this.lang
  }
  setLocale(lang: string) {
    this.lang = lang
    localStorage.setItem('language', lang)
  }
}

export const $i18n = new I18n()

export function getWeek(weekday: number, withPrefix = false) {
  if (weekday < 0) return ''
  let strArr: string[] = []
  switch (getBrowserLanguage()) {
    case 'zh_CN':
      strArr = ['日', '一', '二', '三', '四', '五', '六']
      break
    case 'en_US':
      strArr = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']
      break
    default:
      strArr = ['日', '一', '二', '三', '四', '五', '六']
      break
  }
  const str = strArr[weekday % strArr.length]
  if (withPrefix) {
    return weekPrefix() + str
  }
  return str
}

export function weekPrefix() {
  switch (getBrowserLanguage()) {
    case 'zh_CN':
      return '周'
    case 'en_US':
      return ''
    default:
      return ''
  }
}