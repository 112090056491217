import React, { useState, CSSProperties } from 'react'
import './index.scss'
import { $i18n } from '@/lang'
import { Select } from 'antd'
const { Option } = Select

interface SelectorPickerProps {
  onChange?: (value: string) => void;
  defaultValue?: any
  options?: any[]
  placeholder?: string;
  hasHoursUnit?: boolean;
  labelKey?: string;
  rcKey?: any;
  valueKey?: string;
  allowClear?: boolean;
  onClear?: () => void
  style?: CSSProperties;
}

const SelectorPicker: React.SFC<SelectorPickerProps> = (props) => {

  const {
    onChange,
    onClear,
    defaultValue = undefined,
    placeholder,
    labelKey = 'label',
    rcKey = 'key',
    valueKey = 'value',
    allowClear = false,
    options = [],
    hasHoursUnit,
    style
  } = props

  const [selectValue, setSelectValue] = useState(defaultValue)

  return (
    <div className="selector-picker-container">
      <Select
        className="custom-ant-select"
        placeholder={placeholder}
        value={selectValue}
        allowClear={allowClear}
        bordered={false}
        onChange={(value) => {
          setSelectValue(value)
          onChange && onChange(value)
        }}
        onClear={onClear}
        style={style}
      >
        {
          options.map((item) => {
            return (
              <Option
                value={item[valueKey]}
                key={item[rcKey]}
              >{item[labelKey]}</Option>
            )
          })
        }
      </Select>
      { hasHoursUnit && <div className="selector-picker-unit">{$i18n.$t('work-hours-unit')}</div> }
    </div>
  )
}

SelectorPicker.defaultProps = {
  hasHoursUnit: false
}

export default SelectorPicker
