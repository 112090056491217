import { observable, action, runInAction, makeAutoObservable } from 'mobx'
import { ApiGetProject } from '@/api'
import { Dayjs } from 'dayjs'
import { generateQuickDate } from '@/utils/date'

class ProjectStore {

  constructor() {
    makeAutoObservable(this, {
      dateRange: observable,
      params: observable,
      list: observable,
      fetchData: action
    })
  }
  // TODO
  dateRange: Dayjs[] = generateQuickDate().week

  params = {
    size: 10,
    current: 1,
    total: 0,
    status: 0 as number | null,
    name: ''
  }

  list = [] as Project[]

  // @action.bound
  async fetchData () {
    const params: {
      page: number;
      page_size: number;
      status?: number | null;
      project_name: string;
      platform: number;
      filter: number;
    } = {
      page: this.params.current,
      page_size: this.params.size,
      status: this.params.status,
      project_name: this.params.name,
      platform: 2,
      filter: 1
    }
    if (params.status !== 0 && !params.status) {
      this.params.status = null
      delete params.status
    }
    const res = await ApiGetProject(params)
    if (res.retCode === 0) {
      runInAction(() => {
        this.list = res.data.map((data: { project_member: any[] }) => ({
          ...data,
          project_member: data.project_member.map(member => ({
            ...member,
            openId: member.open_id
          }))
        }))
        this.params.total = res.count || 0
      })
    }
  }
}

export default new ProjectStore()
