import React from 'react'
import './index.scss'
import { observer } from 'mobx-react';
import { ApiExport } from '@/api';
import { $i18n } from '@/lang';
import { generateQuickDate } from '@/utils/date';
import DepartmentStore from '@/store/department'
import DateSelect from '@/components/date-select';
import HoursColor from '@/components/hours-color';
import HoursTable from '@/components/hours-table';
import { message, Breadcrumb, Spin } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom'
import user from '@/store/user'
import LeaderIcon from '@/assets/images/department/leader.png'

@observer
class Department extends React.Component<RouteComponentProps> {

  i18nTitle = $i18n.$t('app-name')

  state = {
    dateRange: generateQuickDate().week
  }

  // UNSAFE_componentWillMount () {
  //   DepartmentStore.getDepartmentData()
  // }
  componentDidMount () {
    document.title = this.i18nTitle
    if (user.userInfo) {
      DepartmentStore.getDepartmentData({
        id: user.userInfo.id,
        name: user.userInfo.name
      })
    }
  }

  async export () {
    message.loading({
      content: $i18n.$t('exporting'),
      key: 'exporting',
      duration: 0
    })
    const res = await ApiExport({
      start_date: DepartmentStore.dateRange[0].unix(),
      end_date: DepartmentStore.dateRange[1].unix()
    })
    message.destroy('exporting')
    if (res.retCode === 0) {
      // @ts-ignore
      // const downloadUrl = `${config.WEB_URL}download?path=${res.data}&lang=${$i18n.getLocale()}`
      // window.open(encodeURIComponent(downloadUrl), '_blank')
      const href = res.data
      var a = document.createElement('a');
      a.setAttribute('href', href);
      a.setAttribute('download', href);
      document.body.appendChild(a);
      a.click()
      document.body.removeChild(a)
    }
  }

  render () {
    return (
      <div className="department base-page">
        <div className="department-header">
          <div className="header-container">
            <div className="date-selector">
              <DateSelect
                onChange={(range) => {
                  DepartmentStore.changeDateRange(range)
                }}
                defaultValue={DepartmentStore.dateRange.map(i => i.unix() * 1000)}
              ></DateSelect>
            </div>
            <div className="right-export base-hover" onClick={() => this.export()}>
              <span>{$i18n.$t('export')}</span>
            </div>
          </div>
        </div>
        <div className="department-container">
          <div className="table">
            <div className="table-container">
              <div className="title-info">
                <div className="info-container">
                  <div className="left">
                    <div className="all-member">
                      <div className="all-icon">
                        <i className="icon-font">&#xe65d;</i>
                      </div>
                      <span className="all-text" onClick={() => {
                        DepartmentStore.getDepartmentData(DepartmentStore.users[0], 0)
                      }}>我的团队</span>
                      <Breadcrumb>
                        {
                          DepartmentStore.users.slice(1).map((item, index) =>
                            <Breadcrumb.Item key={`bre_${item.id}`}>
                              <span onClick={() => {
                                DepartmentStore.getDepartmentData(item, index + 1)
                              }} className={index < DepartmentStore.users.length - 2 ? "click" : 'unclick'}>
                                {item.name}的团队
                              </span>
                            </Breadcrumb.Item>
                          )
                        }
                      </Breadcrumb>
                    </div>
                    <div className="current-total">
                      {`${$i18n.$t('total-time-during-this-period')}：${DepartmentStore.departmentData.tableTitle.totalHours}h`}
                    </div>
                  </div>
                  <div className="right">
                    <HoursColor></HoursColor>
                  </div>
                </div>
              </div>
              <Spin spinning={DepartmentStore.fetching}>
                <HoursTable
                  dateRange={DepartmentStore.dateRange}
                  datas={DepartmentStore.hoursTableData}
                  mode={1}
                  renderUserRight={(user) => {
                    const find = DepartmentStore.userData.find(item => item.id === user.id)
                    if (find && find.is_leader) {
                      return <div
                        onClick={() => {
                          DepartmentStore.getDepartmentData(find, DepartmentStore.users.length)
                        }}
                        className="leader"
                      >
                        <img src={LeaderIcon} alt="" />
                      </div>
                      // DepartmentStore.getDepartmentData(user, DepartmentStore.users.length)
                    } else {
                      return null
                    }
                  }}
                ></HoursTable>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Department)
