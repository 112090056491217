import React from 'react'
import { Button } from 'antd'

import './index.scss'

interface BaseButtonProps {
  customType?: 'primary' | 'default' | string;
  style?: React.CSSProperties;
  loading?: boolean
}

// TODO: 需要手动赋事件
const BaseButton: React.ComponentType<React.ButtonHTMLAttributes<HTMLButtonElement> & BaseButtonProps> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      className={`base-button base-hover ${props.customType} ${props.className}`}
      style={props.style}
      loading={props.loading}
    >{props.children}</Button>
  )
}

export default BaseButton
