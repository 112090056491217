import React from 'react'

import './index.scss'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import { fillDate } from '@/utils/date'
import { getWeek, $i18n } from '@/lang'
import { generateColor } from '@/utils/hours'
import BaseBubbleDialog from '@/components/base-bubble-dialog'
import userStore from '@/store/user'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface HoursTableProps {
  showAddRow?: boolean;
  /** 是否展示小弹框，默认展示 */
  showBubbleDialog?: boolean;
  onBubbleCLick?: (hoursDetail: any) => void;
  dateRange: Dayjs[],
  datas: HourTableRow[],
  /** 行头显示模式：0项目 1用户 */
  mode?: number;
  projectId?: number;
  clickUser?: (data: {
    name: string
    id: number
  }) => void
  renderUserRight?: (data: {
    /** 用户id */
    id: number
  }) => JSX.Element | null
}
@observer
class HoursTable extends React.Component<HoursTableProps & {user?: any} & RouteComponentProps> {

  state = {
    position: {
      x: 0,
      y: 0
    },
    visibled: false,
    dialogProps: {
      self: false,
      name: '',
      title: '',
      avatarUrl: '',
      currentTimestamp: dayjs().unix(),
      oneDayHoursTotal: 0,
      selfAvatarUrl: '',
      selfName: '',
      hoursDetail: [] as any[] | null,
      projectDetail: [] as any[] | null,
      projectId: 0
    },
    startCellLeft: 0
  }

  static defaultProps = {
    showAddRow: false,
    showBubbleDialog: true,
    mode: 0
  }

  get dates () {
    return fillDate(this.props.dateRange[0], this.props.dateRange[1])
  }

  getPosition () {
    const defalutPosition = {
      x: 0,
      y: 0
    }
    return new Promise<{x: number, y: number}>((resolve) => {
      if (this.tableRef.current) {
        const res = this.tableRef.current.getBoundingClientRect()
        resolve({
          x: res.left,
          y: res.top
        })
      } else {
        resolve(defalutPosition)
      }
    })
  }

  async overTableItem (data: HourTableDataItem, row: HourTableRow, elementId?: string) {
    const id = userStore.userInfo?.id
    let { dialogProps } = this.state

    dialogProps.avatarUrl = row.user.avatar
    dialogProps.name = row.user.name
    dialogProps.selfAvatarUrl = row.user.avatar
    dialogProps.selfName = row.user.name
    dialogProps.currentTimestamp = data.date.unix()
    dialogProps.title = `${getWeek(data.date.day(), true)} ${data.date.format('MM/DD')}`
    dialogProps.oneDayHoursTotal = data.hour
    if (row.project) {
      dialogProps.projectId = row.project.id
    }
    dialogProps.hoursDetail = null
    if (data.workHours && data.workHours.length > 0) {
      dialogProps.hoursDetail = data.workHours
    }
    dialogProps.projectDetail = null
    if (data.projects && data.projects.length > 0) {
      dialogProps.projectDetail = data.projects
    }
    dialogProps.self = row.user.id === id
    if (elementId) {
      const res = (document.querySelector(`#${elementId}`) as HTMLDivElement).getBoundingClientRect()
      const position = await this.getPosition()
      this.setState({
        position: {
          x: res.left + (res.width / 2) - position.x,
          y: res.top + res.height - position.y
        },
        visibled: true,
        dialogProps
      })
    }
  }

  getDataByDate (date: Dayjs, datas: HourTableDataItem[]) {
    const result = datas.find(item => {
      return item.date.isSame(date, 'd')
    })
    return result
  }

  tableRef = React.createRef<HTMLDivElement>()

  render () {
    const { position, visibled, dialogProps } = this.state
    const id = userStore.userInfo?.id
    return (
      <div className="hours-table" ref={this.tableRef}>
        <div className="table-new">
          <div className="table-left">
            <div className="extra-view">
              <div className="row-titles">
                <div className="table">
                  <div className="table-header row">
                    <div className="header-item col"></div>
                  </div>
                  <div className="table-body">
                  {
                    this.props.showAddRow &&
                    <div className="row add-row">
                      <div className="col add-item"></div>
                    </div>
                  }
                  {
                    this.props.datas.map((row, index) => {
                      const isProject = this.props.mode === 0 && !!row.project
                      const projectName = row.project ? row.project.name : ''
                      return (
                      <div className="row" key={'row' + index}>
                        <div className="col">
                          {
                            isProject ?
                            <div
                              className="table-item project"
                              onClick={() => {
                                this.props.history.push(`/home/project/${row.project ? row.project.id : 0}`)
                              }}
                            >{projectName}</div> :
                            <div className="table-item user" onClick={() => this.props.clickUser?.(row.user)}>
                              <div className="avatar">
                                <img
                                  className="img"
                                  src={row.user.avatar}
                                  alt=""
                                ></img>
                              </div>
                              <div className="name">{row.user.name || 'user_id：' + row.user.id}</div>
                              {this.props.renderUserRight?.(row.user)}
                            </div>
                          }
                        </div>
                      </div>
                    )})}
                  </div>
                </div>
              </div>
              <div className="counter">
                <div className="table">
                  <div className="table-header row">
                    <div className="header-item col">{$i18n.$t('total')}</div>
                  </div>
                  <div className={`table-body hasBB`}>
                  {
                    this.props.showAddRow &&
                    <div className="row add-row">
                      <div className="col add-item"></div>
                    </div>
                  }
                  {
                    this.props.datas.map((dataItem, rowIndex) => {
                      const count = dataItem.hours.reduce((count, item) => count + item.hour, 0)
                      return (
                      <div className="row" key={'data-item' + rowIndex}>
                        <div className="col">
                          <div className="table-item">{count}</div>
                        </div>
                      </div>
                    )})}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-right">
            <div className="table">
              <div className="table-header row">
                {
                  this.dates.map((date, index) => (
                  <div className="header-item col" key={'table-right-row' + index}>
                    <div className="week">{getWeek(date.day(), true)}</div>
                    <div className="date">
                    {date.format('MM/DD')}
                    </div>
                  </div>
                  ))
                }
              </div>
              <div className={`table-body hasBB`}>
                {
                  this.props.showAddRow &&
                  <div className="row add-row">
                    {
                      this.dates.map((date, index) => (
                      <div className="col add-item" key={'table-body-row' + index}>
                        <div
                          className={`add-icon ${date.isSame(dayjs(), 'd') && 'today'}`}
                          onClick={() => {
                            this.props.onBubbleCLick && this.props.onBubbleCLick(
                              {
                                title: `${getWeek(date.day(), true)} ${date.format('MM/DD')}`,
                                currentTimestamp: date.unix(),
                                hoursDetail: []
                              }
                            )
                          }}
                        >
                          <span className="icon-font">&#xe65a;</span>
                        </div>
                      </div>
                      ))
                    }
                  </div>
                }

                {
                  this.props.datas.map((dataItem, rowIndex) => {
                    return (
                    <div className="row" key={'table-row' + rowIndex}>
                      {this.dates.map((date, colIndex) => {
                        const result = this.getDataByDate(date, dataItem.hours)
                        const project_id = this.props.projectId ? `project_${this.props.projectId}_` : ''
                        const eleId = project_id + `table_row_${rowIndex}_${colIndex}`
                        return <div className="col" key={'table-col' + colIndex}>
                          <div className="table-item">
                          {
                            result ?
                            <div
                              className="date-block"
                              id={eleId}
                              style={{ backgroundColor: `${generateColor(result.hour)}` }}
                              onMouseOver={(event) => {
                                event.stopPropagation()
                                this.overTableItem(result, dataItem, eleId)
                              }}
                              onMouseLeave={() => {
                                this.setState({
                                  visibled: false
                                })
                              }}
                              onClick={() => {
                                if (dataItem.user.id === id) {
                                  this.props.onBubbleCLick && this.props.onBubbleCLick({
                                    title: `${getWeek(date.day(), true)} ${date.format('MM/DD')}`,
                                    currentTimestamp: date.unix(),
                                    projectId: dataItem.project ? dataItem.project.id : '',
                                    hoursDetail: result.workHours
                                  })
                                }
                              }}
                            >{result.hour}</div> :
                            <div style={{
                              width: '100%',
                              height: '100%'
                            }}>
                              {
                                this.props.showAddRow &&
                                <div
                                  className="add-block"
                                  onClick={() => {
                                    this.props.onBubbleCLick && this.props.onBubbleCLick(
                                      {
                                        title: `${getWeek(date.day(), true)} ${date.format('MM/DD')}`,
                                        currentTimestamp: date.unix(),
                                        projectId: dataItem.project ? dataItem.project.id : '',
                                        hoursDetail: []
                                      }
                                    )
                                  }}
                                >
                                  <i className="icon-font">&#xe65a;</i>
                                </div>
                              }
                            </div>
                          }
                          </div>
                        </div>
                        })}
                    </div>
                    )})
                }
              </div>
            </div>
          </div>
        </div>
        <div
          className={`bubble-box ${visibled ? 'visibled' : ''}`}
          style={{
            top: position && position.y + 'px',
            left: position && position.x + 'px'
          }}
          onMouseEnter={() => {
            this.setState({
              visibled: true
            })
          }}
          onMouseLeave={() => {
            this.setState({
              visibled: false
            })
          }}
        >
          <BaseBubbleDialog>
            {
              dialogProps.projectDetail
              ? (
                // 全部人员查询展示项目详情
                <div className="bubble-container">
                  <div className="header">
                    <div className="week-day">
                      <span>{dialogProps.title}</span>
                    </div>
                    <div className="user">
                      <div className="name">{dialogProps.name}</div>
                      <div className="avatar">
                        <img
                          className="img-icon"
                          src={dialogProps.avatarUrl}
                          alt=""
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="member-body">
                    {
                      (dialogProps.projectDetail && dialogProps.projectDetail.length > 0)
                      ? (
                        <div>
                          {
                            (dialogProps.projectDetail).map((oneProject, oneWorkHourIndex) => (
                              <div className="control" key={oneWorkHourIndex}>
                                <div className="project">
                                  <div className="icon"><i className="icon-font">&#xe65f;</i></div>
                                  <div className="name">
                                    <span
                                      className="name-text"
                                      onClick={() => {
                                        this.props.history.push(`/home/project/${oneProject.projectId}`)
                                      }}
                                    >{oneProject.project}</span>
                                  </div>
                                </div>
                                <div className="hour">
                                  <div className="icon"><i className="icon-font">&#xe65e;</i></div>
                                  <div className="hour-text"><span>{`${oneProject.projectHours}h`}</span></div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )
                      : (
                        <div>
                          {
                            <div className="control">
                              <div className="project">
                                <div className="icon"><i className="icon-font">&#xe65f;</i></div>
                                <div className="name"><span>-</span></div>
                              </div>
                              <div className="hour">
                                <div className="icon"><i className="icon-font">&#xe65e;</i></div>
                                <div className="hour-text"><span>0h</span></div>
                              </div>
                            </div>
                          }
                        </div>
                      )
                    }
                  </div>
                  <div className="member-footer">
                    <div className="total-text">{$i18n.$t('total-time')}{`：${dialogProps.oneDayHoursTotal}h`}</div>
                  </div>
                </div>
              )
              : (
                // 个人查询展示工时详情
                <div className="bubble-container">
                  <div className="header">
                    <div className="week-day">
                      <span>{dialogProps.title}</span>
                    </div>
                    <div className="user">
                      <div className="name">{dialogProps.self ? dialogProps.selfName : dialogProps.name}</div>
                      <div className="avatar">
                        <img
                          className="img-icon"
                          src={dialogProps.self ? dialogProps.selfAvatarUrl : dialogProps.avatarUrl}
                          alt=""
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="body">
                    {
                      dialogProps.hoursDetail
                      ? (
                        <div>
                          {
                            (dialogProps.hoursDetail).map((oneWorkHour, index) => (
                              <div className="control" key={'control' + index}>
                                <div className="hour-remark">
                                  <div className="icon"><i className="icon-font">&#xe65c;</i></div>
                                  <div className="remark">{`${oneWorkHour.remark || '-'}`}</div>
                                </div>
                                <div className="hour">
                                  <div className="icon"><i className="icon-font">&#xe65e;</i></div>
                                  <div className="hour-text"><span>{`${oneWorkHour.working_hour}h`}</span></div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )
                      : (
                        <div>
                          {
                            <div className="control">
                              <div className="remark">-</div>
                              <div className="hour">
                                <div className="icon"><i className="icon-font">&#xe603;</i></div>
                                <div className="hour-text"><span>0h</span></div>
                              </div>
                            </div>
                          }
                        </div>
                      )
                    }
                  </div>
                  <div className="bubble-dialog-footer">
                    <div className="total">
                      <div className="total-text">{$i18n.$t('total-time')}{`：${dialogProps.oneDayHoursTotal}h`}</div>
                    </div>
                    {
                      dialogProps.self &&
                      <div
                        className="edit-btn base-hover"
                        onClick={() => {
                          let dayDetail = {
                            title: dialogProps.title,
                            currentTimestamp: dialogProps.currentTimestamp,
                            projectId: dialogProps.projectId,
                            hoursDetail: dialogProps.hoursDetail
                          }
                          this.props.onBubbleCLick && this.props.onBubbleCLick(dayDetail)
                        }}
                      >
                        {`${dialogProps.hoursDetail && dialogProps.hoursDetail.length > 0 ? $i18n.$t('pc-bubble-edit') : $i18n.$t('pc-bubble-add')}`}
                      </div>
                    }
                  </div>
                </div>
              )
            }
          </BaseBubbleDialog>
        </div>
      </div>
    )
  }
}

export default withRouter(HoursTable)
