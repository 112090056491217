import { $i18n } from "@/lang"

function generateHoursList () {
  let hoursArr: {
    value: number,
    label: string
  }[] = []
  let hours = 0.5
  let index = 0
  while (index < 48) {
    const h = {
      value: hours * (index + 1),
      label: `${hours * (index + 1)}${$i18n.$t('work-hours-unit')}`
    }
    hoursArr.push(h)
    index += 1
  }
  return hoursArr
}

export const TIPS_LIST = [
  {
    color: '#b9d6ea',
    tip: '0h < h ≤ 2h'
  },
  {
    color: '#9cc8e2',
    tip: '2h < h ≤ 4h'
  },
  {
    color: '#7bb6d9',
    tip: '4h < h ≤ 6h'
  },
  {
    color: '#5ba3cf',
    tip: '6h < h ≤ 8h'
  },
  {
    color: '#408ec4',
    tip: '8h < h ≤ 12h'
  },
  {
    color: '#2978b8',
    tip: '12h < h ≤ 16h'
  },
  {
    color: '#1661a8',
    tip: '16h < h ≤24h'
  }
]

export const HOUR_LIST = generateHoursList()

export const DATA_ANALYZE_WEEK_TOTAL_WORK_HOURS_CHART_ID = 'data-analyze-week-total-work-hours-chart-id'
export const DATA_ANALYZE_PAY_WORK_HOURS_CHART_ID = 'data-analyze-pay-work-hours-chart-id'
export const DATA_ANALYZE_CUSTOMER_CHART_ID = 'data-analyze-customer-chart-id'
export const DATA_ANALYZE_PROJECT_CHART_ID = 'data-analyze-project-chart-id'
export const DATA_ANALYZE_TEAM_CHART_ID = 'data-analyze-team-chart-id'
export const DATA_ANALYZE_TASK_CHART_ID = 'data-analyze-task-chart-id'

export const PROJECT_CREATE_DEFAULT_COLOR = ['#E47F45', '#F2C649', '#55A587', '#518CB5', '#3969AA', '#503C7D', '#000000', '#7A706B']

export const ADD_TASK_TYPE_ID = 9999