import React from 'react'
import './index.scss'
import DateSelect from '@/components/date-select'
import { ApiPCProjectStatistics, ApiUpdateProjectInfo, ApiExport } from '@/api'
import { formatRange, generateQuickDate } from '@/utils/date'
import dayjs, { Dayjs } from 'dayjs'
import HoursTable from '@/components/hours-table'
import HoursColor from '@/components/hours-color'
import HoursForm from '@/components/hours-form'
import { $i18n } from '@/lang'
import groupBy from 'lodash/groupBy'
import { message, Modal } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import qs from 'querystring'

class ProjectAnalyze extends React.Component<RouteComponentProps<{projectId: string}>> {

  i18nTitle = $i18n.$t('app-name')

  state = {
    dateRange: generateQuickDate().week,
    isOwner: false,
    projectInfo: {
      id: 0,
      name: '',
      hour: 0,
      members: 0,
      creator: '',
      create_at: 0,
      start: 0,
      end: 0,
      status: 0
    },
    projectDatas: [] as ProjectDataItem[],
    editWorkHour: {
      visibled: false,
      form: {
        title: '',
        currentTimestamp: 0,
        projectId: 0,
        hoursDetail: []
      }
    }
  }

  get activeDate () {
    return formatRange(this.state.projectInfo.start || 0, this.state.projectInfo.end || 0)
  }

  get createDate () {
    if (!this.state.projectInfo.create_at) return '-'
    return dayjs(this.state.projectInfo.create_at * 1000).format('YYYY.MM.DD HH:mm:ss')
  }

  changeReange (range: Dayjs[]) {
    this.setState({
      dateRange: range
    }, () => {
      this.fetchData()
    })
  }


  async fetchData () {
    message.loading({
      content: $i18n.$t('loading'),
      key: 'loadingProject',
      duration: 0
    })
    const [ start, end ] = this.state.dateRange
    const project_id = this.props.match.params.projectId
    const res = await ApiPCProjectStatistics({
      project_id,
      start_date: start.unix(),
      end_date: end.endOf('d').unix()
    })

    message.destroy('loadingProject')

    if (res.retCode === 0 && res.data.project_data.length > 0) {
      const projectInfo: ProjectDataItem = res.data.project_data[0]
      const workHourInfo: any = res.data.all_project_working_hours
      this.setState({
        isOwner: res.data.is_project_owner || false,
        projectInfo: {
          name: projectInfo.project_name,
          hour: projectInfo.project_all_hours,
          members: workHourInfo.working_hours_member_count,
          creator: projectInfo.creator,
          create_at: projectInfo.created_at,
          start: projectInfo.start_time,
          end: projectInfo.end_time,
          status: projectInfo.status,
          id: projectInfo.project
        },
        projectDatas: res.data.project_data
      })
    }
  }

  get workHoursList () {
    const projectData = this.state.projectDatas
    return projectData.map((project) => {
      return {
        ...project,
        tableDatas: project.user_data.user.map((user) => {
          let [work_hours] = user.work_hours
          const groupData = groupBy(work_hours.map(i => ({
            ...i,
            remark: i.remark || '',
            work_date: dayjs.unix(i.work_date).startOf('d').unix()
          })), 'work_date')
          return {
            user: {
              id: user.id,
              name: user.name,
              avatar: user.head_img_url
            },
            hours: Object.keys(groupData).map((dateKey) => {
              return {
                hour: groupData[dateKey].reduce((count, i) => count + i.working_hour, 0),
                date: dayjs.unix(Number(dateKey)),
                workHours: groupData[dateKey],
              }
            })
          }
        })
        .sort((a, b) => {
          const [aCount, bCount] = [a, b].map((item) => item.hours.reduce((count, i) => count + i.hour, 0))
          return bCount - aCount
        })
      }
    })
  }

  async archivedProject () {
    if (this.state.projectInfo.name === '') return
    const action = async () => {
      message.loading({
        content: $i18n.$t('loading'),
        key: 'loadingArchivedProject',
        duration: 0
      })
      const res = await ApiUpdateProjectInfo({
        id: this.state.projectInfo.id,
        status: this.state.projectInfo.status === 1 ? 0 : 1
      })
      message.destroy('loadingArchivedProject')

      if (res.retCode === 0) {
        this.fetchData()
      }
    }
    if (this.state.projectInfo.status === 0) {
      Modal.confirm({
        title: $i18n.$t('archive-title'),
        content: $i18n.$t('archive-content'),
        cancelText: $i18n.$t('cancel'),
        okText: $i18n.$t('alert-confirm'),
        onOk: async (res) => {
          action()
        }
      })
    } else {
      action()
    }
  }

  componentDidMount () {
    const { start, end } = qs.parse(this.props.location.search.slice(1))
    if (start && end) {
      this.setState({
        dateRange: [dayjs(Number(start) * 1000), dayjs(Number(end) * 1000)]
      }, () => {
        this.fetchData()
      })
    } else {
      this.fetchData()
    }
  }

  async export () {
    message.loading({
      content: $i18n.$t('exporting'),
      key: 'export-loading',
      duration: 0
    })
    const res = await ApiExport({
      start_date: this.state.dateRange[0].unix(),
      end_date: this.state.dateRange[1].unix(),
      project_id: this.state.projectInfo.id
    })
    if (res.retCode === 0) {
      message.destroy('export-loading')
      // TODO: 导出
      // const downloadUrl = `${config.WEB_URL}download?path=${res.data}&lang=${$i18n.getLocale()}`
      // window.open(encodeURIComponent(downloadUrl), '_blank')
      const href = res.data
      var a = document.createElement('a');
      a.setAttribute('href', href);
      a.setAttribute('download', href);
      document.body.appendChild(a);
      a.click()
      document.body.removeChild(a)
    }
  }

  render () {
    return (
      <div className="project-analyze-page base-page">
        <div className="top-filter">
          <div className="date-select">
            <DateSelect
              defaultValue={this.state.dateRange.map(i => i.unix() * 1000)}
              onChange={(value) => this.changeReange(value)}
            />
          </div>
          {
            this.state.isOwner && <>
            <div className="archive base-hover" onClick={() => this.archivedProject()}>
              {this.state.projectInfo.status === 0 ? $i18n.$t('archive') : $i18n.$t('cancel-archive')}
            </div>
            <div className="export base-hover" onClick={() => this.export()}>
              {$i18n.$t('export')}
            </div>
            </>
          }
        </div>

        <div className="project-analyze-container">

          <div className="content">
            <div className="project-name">{this.state.projectInfo.name}</div>

            <div className="total-view">
              <div className="item">
                <div className="number">{this.state.projectInfo.hour}</div>
                <div className="label">{$i18n.$t('project-work-hour')}</div>
              </div>
              <div className="item">
                <div className="number">{this.state.projectInfo.members}</div>
                <div className="label">{$i18n.$t('project-member')}</div>
              </div>
              <div className="item project-info">
                <div className="line">{$i18n.$t('project-creator')}: {this.state.projectInfo.creator}</div>
                <div className="line">{$i18n.$t('project-creation-time')}: {this.createDate}</div>
                {/* <div className="line">{$i18n.$t('start-and-end-time')}: {this.activeDate}</div> */}
              </div>
            </div>

            <div className="project-tables">
              {
                this.workHoursList.map((item, index) => {
                  return (
                  <div key={`table_${index}`}>
                    <div className="project-info">
                      <div className="info-container">
                        <div className="name">
                          <i className="icon-font">&#xe65f;</i>
                          {item.project_name}
                        </div>
                        <div className="datas">
                          <div className={`item ${item.pre_working_hours && (item.project_all_hours > Number(item.pre_working_hours)) && 'overtime'}`}>{$i18n.$t('cumulative-time')}：{item.project_all_hours}h</div>
                          <div className="item">{$i18n.$t('budget-time')}：{item.pre_working_hours ? `${item.pre_working_hours}h` : '-'}</div>
                          <div className="item">{$i18n.$t('time-of-day')}：{item.total_working_hours}h</div>
                        </div>
                      </div>
                      {
                        index === 0 &&
                        <div className="color-indicator">
                          <HoursColor></HoursColor>
                        </div>
                      }
                    </div>
                    <HoursTable
                      onBubbleCLick={(event) => {
                        this.setState({
                          editWorkHour: {
                            visibled: true,
                            form: {
                              ...event,
                              projectId: item.project
                            }
                          }
                        })
                      }}
                      dateRange={this.state.dateRange}
                      datas={item.tableDatas}
                    ></HoursTable>
                  </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <HoursForm
          visibled={this.state.editWorkHour.visibled}
          formData={this.state.editWorkHour.form}
          onOnlyCLose={(close) => {
            this.setState({
              editWorkHour: {
                ...this.state.editWorkHour,
                visibled: close
              }
            })
          }}
          onFetchData={(close) => {
            this.setState({
              editWorkHour: {
                ...this.state.editWorkHour,
                visibled: close
              }
            })
            this.fetchData()
          }}
        ></HoursForm>
      </div>
    )
  }
}

export default withRouter(ProjectAnalyze)
