import dayjs, { Dayjs } from 'dayjs'
import { $i18n } from '@/lang'

export function generateQuickDate () {
  let weekstart = dayjs().startOf('week').add(1, 'd')
  if (dayjs().day() === 0) {
    weekstart = dayjs().add(-1, 'd').startOf('week').add(1, 'd')
  }
  const start = dayjs().add(-15, 'd')
  const end = dayjs().add(15, 'd')
  return {
    week: [weekstart, weekstart.add(6, 'd')],
    twoweek: [weekstart, weekstart.add(13, 'd')],
    month: [start, end]
  }
}

/**
 * 根据起始时间和结束时间填充时间数组
 * @param start 开始时间
 * @param end 结束时间
 * @param step 位移数
 * @param unit 位移单位
 */
export function fillDate (start: Dayjs, end: Dayjs, step: number = 1, unit: dayjs.OpUnitType = 'd') {
  if (end.isBefore(start)) return []
  let _start = start
  const result: Dayjs[] = [start]
  do {
    _start = _start.add(step, unit)
    result.push(_start)
  } while (!_start.isSame(end, unit))
  return result
}

export function formatRange (startTime: number, endTime: number, emptyStr = '-') {
  if (!startTime) return emptyStr
  let start = dayjs(startTime * 1000).format('YYYY.MM.DD')
  let end
  if (endTime) {
    let format = 'YYYY.MM.DD'
    if (dayjs(endTime * 1000).isSame(dayjs(startTime * 1000), 'year')) {
      format = 'MM.DD'
    }
    end = dayjs(endTime * 1000).format(format)
  } else {
    end = $i18n.$t('now')
  }
  return `${start} - ${end}`
}

/**
 * 根据日期生成周数据
 * @param date 当前时间
 */
export function generateRange (date = dayjs()) {
  // MARK: 修改时间起始
  if (date.day() === 0) {
    date = date.add(-1, 'd')
  }
  const start = date.startOf('week').add(1, 'd') // MARK: 修改时间起始
  const dates: Dayjs[] = []
  for (let i = 0; i < 7; i++) {
    dates.push(start.add(i, 'd'))
  }
  return dates
}

export function formatTime (time: Dayjs, format = 'YYYY.MM.DD') {
  if (!time) {
    return ''
  }
  return dayjs(time).format(format)
}