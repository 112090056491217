import { getBrowserLanguage } from "@/lang"

const language = getBrowserLanguage()

export function getLanguage () {
  switch (language) {
    case 'zh_CN': return 'zh-hans'
    case 'zh': return 'zh-hans'
    case 'en_US': return 'en'
    default: return 'zh-hans'
  }
}

const config = {
  development: {
    ROOT_API_URL: `https://timebook-staging-api.xiliutech.com/${getLanguage()}/api/v2`,
    WEB_URL: `https://tb-web.xiliutech.com/`
  },
  staging: {
    ROOT_API_URL: `https://timebook-staging-api.xiliutech.com/${getLanguage()}/api/v2`,
    WEB_URL: `https://vividlog-web.xiliutech.com/#/`
  },
  production: {
    ROOT_API_URL: `https://timebook-api.xiliutech.com/${getLanguage()}/api/v2`,
    WEB_URL: `https://vividlog-web.xiliutech.com/#/`
  }
}

const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV || 'development'

export default config[env as keyof typeof config]
