/**
 * 存放用户信息数据
 * profile、授权、注册
 */
import { action, makeAutoObservable, observable, runInAction } from 'mobx'
import { ApiGetUserInfo } from '@/api'

class UserStore {

  userInfo: API.UserInfo | null = null
  token: string | null = null

  isLeader = false

  constructor() {
    makeAutoObservable(this, {
      userInfo: observable,
      refreshInfo: action,
      token: observable,
      isLeader: observable,
      removeToken: action,
      updateToken: action
    })

    this.token = localStorage.getItem('token')
    if (this.token) {
      this.refreshInfo()
    }
  }

  async refreshInfo () { // 刷新用户信息
    const res = await ApiGetUserInfo()
    if (res?.retCode === 0) {
      runInAction(() => {
        this.userInfo = res.data.data
        this.isLeader = res.data.is_leader
        console.log(this.userInfo, 'mobx')
      })
    }
  }

  updateToken (token: string) {
    this.token = token
    localStorage.setItem('token', token)
  }

  removeToken () {
    this.token = null
    localStorage.removeItem('token')
  }
}
export default new UserStore()
